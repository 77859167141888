import { Helmet } from "react-helmet";
const metaDecorator = require("../../metaDecorator.json");

const MetaDecorator = ({ title, description, imageUrl, imageDescription }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} data-react-helmet="true" />
      <meta property="og:title" content={title} />
      <meta property="og:type" content="article" />
      <meta property="og:image" content={metaDecorator.hostname + imageUrl} />
      <meta
        property="og:url"
        content={
          metaDecorator.hostname +
          window.location.pathname +
          window.location.search
        }
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="og:description" content={description} />

      <meta name="twitter:image:alt" content={imageDescription} />
    </Helmet>
  );
};

export default MetaDecorator;
