import { useEffect, useState, useMemo } from "react";
import LeaderboardTable from "../LeaderboardTable/LeaderboardTable.component";
import axios from "axios";
import { ReactComponent as TrophyIcon } from "../../assets/icons/trophy-icon.svg";
import Loading from "../Loading/Loading.component";

// import "./leaderboard.styles.css";

const Leaderboard = ({ title }) => {
  const [leaderBoardData, setLeaderBoardData] = useState([]);
  const [loading, setLoading] = useState(false);

  const token = localStorage.getItem("token");

  const config = useMemo(
    () => ({
      headers: { Authorization: `Bearer ${token}` },
    }),
    [token]
  );

  useEffect(() => {
    setLoading(true);
    axios
      .get("/api/v1/user/leaderboard", config)
      .then((res) => {
        setLeaderBoardData(res.data?.users);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [config]);

  let id = 20;
  return (
    <div className="leaderboard__container">
      {loading ? <Loading type="cylon" color="rgb(101, 93, 138)" /> : null}
      {title ? <h1 className="leaderboard__heading">Leaderboard</h1> : null}
      <table className="leaderboard__table">
        <thead>
          <tr>
            <th>#</th>
            <th>User</th>
            <th>Points</th>
          </tr>
        </thead>
        {leaderBoardData?.map((user, index) => (
          <LeaderboardTable
            key={id++}
            name={user.name}
            points={user.pointsWon}
            position={index}
          />
        ))}
      </table>
      <div className="trophy__span">
        <TrophyIcon />
      </div>
    </div>
  );
};

export default Leaderboard;
