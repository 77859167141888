import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { ReactComponent as VideoIcon } from "../../assets/icons/video-icon.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/close-circleTwo.svg";
import { Checkbox, Radio, Switch } from "pretty-checkbox-react";

import "./questionComponent.styles.css";
import "@djthoms/pretty-checkbox";
import QuizOption from "../QuizOption/QuizOption";

const QuestionComponent = ({
  toggleShowVideoPlayer,
  question,
  options,
  currentQuestionNum,
  setCurrentQuestionNum,
  questionsLength,
  selectAnswer,
  image,
  seriesId,
  recordAnswer,
  selectedAnswer,
  setSelectedAnswer,
  questionId,
  markQuestions,
  toggleShowQuestion,
  setCurrentQuestionId,
  showPassOrFail,
  answers,
  questions,
  toggleSubmitPrompt,
}) => {
  const [currentSelected, setCurrentSelected] = useState("");

  const cr = answers.filter((answer) => answer.questionId === questionId);

  const checked = cr[currentQuestionNum]?.answer !== undefined ? true : false;

  console.log({ questions });
  console.log({ currentQuestionNum });

  return (
    <div className="videoPlayer__container--two">
      <div className={!image ? "question__container" : "with__image"}>
        <div className="question__content">
          <span className="question__number-and-close__icon">
            <p className="question__number">
              Question {currentQuestionNum + 1}
            </p>
            <CloseIcon
              onClick={toggleShowQuestion}
              style={{ cursor: "pointer" }}
            />
          </span>
          <span className="question__img--span">
            {image ? (
              <div
                className="question__img"
                style={{
                  backgroundImage: `url(${image})`,
                }}
              />
            ) : null}
          </span>
          <div className="question__main--text-and-options">
            <p className="question__main--text">{question}</p>
            <p className="question__answers--prompt">Pick the correct answer</p>
            <form className="answer__option-and-check">
              {options?.map((option, index) => {
                return (
                  <QuizOption
                    option={option}
                    key={uuidv4()}
                    index={index}
                    currentSelected={currentSelected}
                    setCurrentSelected={setCurrentSelected}
                    selectAnswer={selectAnswer}
                    checked={checked}
                    currentQuestionNum={currentQuestionNum}
                    answers={answers}
                    questionId={questionId}
                  />
                );
              })}
            </form>
          </div>
          <span className="question__nav--CTAs">
            {currentQuestionNum === 0 ? (
              ""
            ) : (
              <button
                className="previous__question--CTA"
                onClick={() => {
                  // recordAnswer(id, currentQuestionNum);
                  setCurrentQuestionNum(currentQuestionNum - 1);
                  setSelectedAnswer("");
                }}
              >
                Previous
              </button>
            )}

            {currentQuestionNum === questionsLength ? (
              <button
                className="finish__CTA"
                onClick={() => {
                  // setCurrentQuestionId(questionId);
                  recordAnswer(questionId, currentQuestionNum);
                  toggleSubmitPrompt();
                }}
                disabled={
                  currentSelected === "" &&
                  answers[currentQuestionNum]?.answer === undefined
                    ? true
                    : false
                }
              >
                Finish
              </button>
            ) : (
              <button
                className="next__question--CTA"
                onClick={() => {
                  if (
                    currentQuestionNum >= 0 &&
                    currentQuestionNum < questionsLength
                  ) {
                    recordAnswer(questionId, currentQuestionNum);
                    setCurrentQuestionNum(currentQuestionNum + 1);
                  }
                }}
                disabled={
                  currentSelected === "" &&
                  answers[currentQuestionNum]?.answer === undefined
                    ? true
                    : false
                }
              >
                Next
              </button>
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

export default QuestionComponent;
