import { ReactComponent as CloseIcon } from "../../assets/icons/close-circleTwo.svg";
import { useCertificateContext } from "../../contexts/CertificateContext";
import "./failed.styles.css";

const FailedComponent = ({
  showPassOrFail,
  setCurrentQuestionNum,
  setShowQuestion,
  setShowResult,
  setAnswers,
}) => {
  const { certificateData, setCertificateData } = useCertificateContext();

  const retryQuiz = () => {
    setCurrentQuestionNum(0);
    setShowQuestion(true);
    setShowResult(false);
    setAnswers([]);
    setCertificateData("");
  };
  const reWatchQuiz = () => {
    setCurrentQuestionNum(0);
    setShowQuestion(false);
    setShowResult(false);
    setAnswers([]);
    setCertificateData("");
  };
  return (
    <div className="videoPlayer__container--two">
      <div className="failed__container">
        <span
          className="question__number-and-close__icon"
          style={{ justifyContent: "flex-end" }}
        >
          <CloseIcon onClick={showPassOrFail} style={{ cursor: "pointer" }} />
        </span>
        <span className="failedText-and-CTAs">
          <p className="failed__text">
            <span style={{ fontWeight: "700" }}>Uh Oh! </span> You didn’t pass.
            You scored{" "}
            <span
              className="scored__percentage"
              style={{ color: "#FF6161", fontWeight: "700" }}
            >
              {" "}
              {certificateData.score}%
            </span>{" "}
            . Better luck next time! 💪
          </p>
          <span className="failed__CTAs">
            <button className="retry__CTA" onClick={() => retryQuiz()}>
              Retry Quiz
            </button>
            <button className="rewatch__CTA" onClick={() => reWatchQuiz()}>
              Re-watch Series
            </button>
          </span>
        </span>
      </div>
    </div>
  );
};

export default FailedComponent;
