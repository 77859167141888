import { ReactComponent as ExpiredIcon } from "../../assets/icons/expired-icon.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/close-circle.svg";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../contexts/AuthContext";
// import Loading from "../Loading/Loading.component";
// import { ReactComponent as XIcon } from "../../assets/icons/x-svg.svg";

import "./overlayPrompt.styles.css";

const OverlayPrompt = ({
  promptText,
  promptButtonTextOne,
  promptButtonTexttwo,
  markQuestions,
  functionOne,
  functionTwo,
  icon,
}) => {
  const { setAuthDetails } = useAuthContext();
  const navigate = useNavigate();

  // const completeQuizSubmission = () => {
  //   markQuestions();
  //   toggleSubmitPrompt();
  // };

  return (
    <div className="payment__options--div">
      <div className="expired__sub--overlay">
        <div className="expired__sub--container">
          {/* <span className="close__expiry--span">
            <CloseIcon
              style={{ cursor: "pointer", marginBottom: "3rem" }}
              onClick={() => {
                setAuthDetails("");
                localStorage.clear();
                navigate("/");
              }}
            />
          </span> */}
          {/* <ExpiredIcon /> */}
          {icon}
          <p className="expired__sub--text">{promptText}</p>
          <button className="renew__sub--CTA" onClick={() => functionOne()}>
            {promptButtonTextOne}
          </button>
          {promptButtonTexttwo ? (
            <button className="dont__submit--CTA" onClick={() => functionTwo()}>
              {promptButtonTexttwo}
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default OverlayPrompt;
