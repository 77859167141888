import axios from "axios";
import { ReactComponent as PlayIcon } from "../../assets/icons/play-iconTwo.svg";

import "./video.styles.css";
const Video = ({
  title,
  type,
  number,
  url,
  getVideoUrl,
  videoUrl,
  toggleShowVideoPlayer,
  showVideoPlayer,
  index,
  id,
  thumbnail,
}) => {
  const openPlayer = (id, index) => {
    if (!showVideoPlayer) {
      toggleShowVideoPlayer();
    } else {
      return;
    }

    if (index === 0) {
      axios.put(`/api/v1/content/markOngoing/${id}`);
    }
  };

  let showNowPlaying = false;

  if (videoUrl === url && showVideoPlayer) {
    showNowPlaying = true;
  }

  return (
    <div className="video__container">
      <div
        className="video__content--container"
        style={{
          backgroundImage: `url(${thumbnail})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        onClick={() => {
          getVideoUrl(url, id, index, title);
          openPlayer(id, index);
        }}
      >
        <span className="tag-and-play--CTA">
          <div className="vid__details--container-box-1">
            <span className="series__video--span">
              <p className="tag__text">Video</p>
            </span>
          </div>
          <div className="vid__details--container-box-2">
            <span className="series__play--span">
              {showNowPlaying ? (
                <h3 className="now__playing--notification">Now playing...</h3>
              ) : (
                <PlayIcon />
              )}
            </span>
          </div>
          <div className="vid__details--container-box-3">
            {/* <span className="completion__span">
              <p className="tag__text" style={{ color: "#08AE2C" }}>
                100%{" "}
                <span
                  style={{ fontWeight: 400, fontSize: "8px", color: "#949699" }}
                >
                  Completed
                </span>
              </p>
            </span> */}
          </div>
        </span>
      </div>
      <span className="series__videoTitle--span">
        <p>
          {number}. {title}
        </p>
      </span>
    </div>
  );
};

export default Video;
