import React, { useState, useContext, createContext } from "react";

const SingleSeriesContext = createContext();

export const useSeriesDetailsContext = () => {
  return useContext(SingleSeriesContext);
};

export const SeriesDetailsProvider = ({ children }) => {
  const [singleSeriesDetails, setSingleSeriesDetails] = useState({});

  return (
    <SingleSeriesContext.Provider
      value={{ singleSeriesDetails, setSingleSeriesDetails }}
    >
      {children}
    </SingleSeriesContext.Provider>
  );
};
