import { useState, useMemo } from "react";
import { ReactComponent as TrashIcon } from "../../assets/icons/trash-icon.svg";
import { ReactComponent as MoreIcon } from "../../assets/icons/more-vertical.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "./usersTableData.styles.css";

const UsersTableData = ({
  name,
  position,
  email,
  status,
  dateJoined,
  userId,
  showChangeDept,
  setShowChangeDept,
  setUserId,
}) => {
  // For getting username acronym

  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);

  const navigate = useNavigate();
  const str = `${name?.toUpperCase()}`;
  const matches = str.match(/\b(\w)/g);
  const acronym = matches.join(" ");

  const token = localStorage.getItem("token");

  const config = useMemo(() => {
    return {
      headers: { Authorization: `Bearer ${token}` },
    };
  }, [token]);

  const deleteUser = () => {
    axios
      .delete(`/api/v1/business/deleteuser/${userId}`, config)
      .then((res) => {
        toast("User Removed");
        window.location.reload();
      })
      .catch((error) => console.log(error));
  };

  const nth = function (d) {
    if (d > 3 && d < 21) return "th";
    switch (d % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  console.log();

  const d = new Date(dateJoined.substr(0, 10));
  let month = months[d.getMonth()];
  const year = d.getFullYear();
  const day = d.getDate();
  const dateJoinedFormated = `${day}${nth(day)} of ${month}, ${year}`;

  return (
    <>
      <tbody>
        <tr className="user__row">
          <td className="user__sn">{position + 1}</td>
          <td>
            <span className="user__image-and-name">
              <span
                className="user__image"
                onClick={() => navigate(`/company-panel/user/${userId}`)}
              >
                {acronym}
              </span>
              <span className="name-and-company">
                <p className="user__name">{name}</p>

                <p className="user-email">{email}</p>
              </span>
            </span>
          </td>
          <td className="state__heading">
            <span className="points-and-icon">
              {/* <UpArrowIcon className="points__icon" /> */}
              <p className="points__figure">{email}</p>
            </span>
          </td>
          <td className="hide__status">
            <span className="points-and-icon">
              {/* <UpArrowIcon className="points__icon" /> */}
              <p
                className={
                  status === "pending"
                    ? "user__status--invited"
                    : "user__status--joined"
                }
              >
                {status === "pending" ? "Invited" : "Joined"}{" "}
              </p>
            </span>
          </td>
          <td className="state__heading">
            <span className="points-and-icon">
              {/* <UpArrowIcon className="points__icon" /> */}
              <p className="points__figure ">{dateJoinedFormated}</p>
            </span>
          </td>
          <td className="user__delete--icon">
            <span className="points-and-icon--trash">
              {/* <UpArrowIcon className="points__icon" /> */}
              <MoreIcon
                className="action__icon"
                onClick={() => setShowMoreOptions(!showMoreOptions)}
              />
              {showMoreOptions ? (
                <div className="more__options-container">
                  <p
                    className="change__department"
                    onClick={() => {
                      setShowChangeDept(!showChangeDept);
                      setUserId(userId);
                      setShowMoreOptions(!showMoreOptions);
                    }}
                  >
                    Change Department
                  </p>
                  <p
                    className="delete__user"
                    onClick={() => {
                      setShowDeletePrompt(!showDeletePrompt);
                      setShowMoreOptions(!showMoreOptions);
                    }}
                  >
                    Delete User
                  </p>
                </div>
              ) : null}
              {showDeletePrompt ? (
                <div className="remove__prompt--box">
                  <p className="remove__prompt--text">
                    Are you sure you want to
                    <br />
                    remove this user?
                  </p>
                  <span className="remove__action--CTAs">
                    <button
                      className="remove__action--CTA"
                      onClick={deleteUser}
                    >
                      Yes, Remove
                    </button>
                    <button
                      className="cancel__CTA"
                      onClick={() => setShowDeletePrompt(!showDeletePrompt)}
                    >
                      Cancel
                    </button>
                  </span>
                </div>
              ) : null}
              {/* <span className="action__options">view User</span> */}
            </span>
          </td>
        </tr>
      </tbody>
    </>
  );
};

export default UsersTableData;
