import { useMemo, useEffect } from "react";
import SideBar from "../SideBar/SideBar.component";
import Dashboard from "./Dashboard/Dashboard.component";
import { ReactComponent as SeriesIcon } from "../../assets/icons/series-icon.svg";
// import { ReactComponent as SeriesIcon } from "../../assets/icons/series-icon.svg";
import { ReactComponent as SeriesIconTwo } from "../../assets/icons/series-iconTwo.svg";
import { ReactComponent as ProgressIcon } from "../../assets/icons/progress-icon.svg";
import { ReactComponent as ProgressIconTwo } from "../../assets/icons/progress-iconTwo.svg";
import { ReactComponent as UserIcon } from "../../assets/icons/user-iconOne.svg";
import { ReactComponent as UserIconTwo } from "../../assets/icons/user-iconTwo.svg";
// import { ReactComponent as UserIcon } from "../../assets/icons/user-icon.svg";
import { useAuthUserContext } from "../../contexts/AuthContext";
import axios from "axios";
import "./panel.styles.css";

const Panel = () => {
  const sideBarLinks = [
    {
      linkId: Date.now(),
      urlName: "Series",
      urlLink: "/company-panel/series",
      urlIcon: <SeriesIcon />,
      urlIconTwo: <SeriesIconTwo />,
    },
    {
      linkId: Date.now(),
      urlName: "My Progress",
      urlLink: `/company-panel/progress`,
      urlIcon: <ProgressIcon />,
      urlIconTwo: <ProgressIconTwo />,
    },

    // {
    //   linkId: Date.now(),
    //   urlName: "Leaderboard",
    //   urlLink: `/company-panel/leaderboard`,
    //   urlIcon: <StarIcon />,
    // },
    // {
    //   linkId: Date.now(),
    //   urlName: "Risk Curve",
    //   urlLink: `/company-panel/riskCurve`,
    //   urlIcon: <AlertIcon />,
    // },
    {
      linkId: Date.now(),
      urlName: "Profile",
      urlLink: `/company-panel/profile`,
      urlIcon: <UserIcon />,
      urlIconTwo: <UserIconTwo />,
    },
    // {
    //   linkId: Date.now(),
    //   urlName: "Users",
    //   urlLink: `/company-panel/users`,
    //   urlIcon: <UsersIcon />,
    // },
  ];

  const token = localStorage.getItem("token");

  const { setAuthUserData } = useAuthUserContext();

  const config = useMemo(() => {
    return {
      headers: { Authorization: `Bearer ${token}` },
    };
  }, [token]);

  useEffect(() => {
    axios
      .get("/api/v1/user/getLoggedInUser", config)
      .then((res) => setAuthUserData(res.data))
      .catch((error) => console.log(error));
  }, [token, config, setAuthUserData]);

  return (
    <div className="panel__container">
      <SideBar sideBarLinks={sideBarLinks} />
      <Dashboard sideBarLinks={sideBarLinks} />
    </div>
  );
};

export default Panel;
