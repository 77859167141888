import { Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
import UserDetailsBar from "../../UserDetailsBar/UserDetailsBar.component";
import "./companyDashboard.styles.css";

const CompanyDashboard = ({
  adminSideideBarLinks,
  logOutPrompt,
  setLogOutPropmt,
}) => {
  const location = useLocation();

  return (
    <div className="dashboard__container">
      <UserDetailsBar
        pathname={location.pathname}
        adminSideideBarLinks={adminSideideBarLinks}
        logOutPrompt={logOutPrompt}
        setLogOutPropmt={setLogOutPropmt}
      />
      <Outlet />
    </div>
  );
};

export default CompanyDashboard;
