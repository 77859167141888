import { useMemo } from "react";
import { ReactComponent as RemoveIcon } from "../../assets/icons/remove-training-icon.svg";
import axios from "axios";
import { toast } from "react-toastify";

const TrainingTab = ({
  name,
  index,
  removeItem,
  removeTraining,
  id,
  removeId,
  userId,
  noRemoveIcon,
}) => {
  const token = localStorage.getItem("token");

  const config = useMemo(() => {
    return {
      headers: { Authorization: `Bearer ${token}` },
    };
  }, [token]);

  const deleteUser = () => {
    userId &&
      axios
        .delete(`/api/v1/business/deleteuser/${userId}`, config)
        .then((res) => {
          toast("User Removed");
          // setTimeout(() => {
          //   navigate(-1);
          // }, 3000);
        })
        .catch((error) => console.log(error));
  };

  return (
    <div className="assigned__training">
      {" "}
      <p className="assigned__training--text">{name}</p>{" "}
      {noRemoveIcon ? null : (
        <RemoveIcon
          style={{ cursor: "pointer" }}
          onClick={() => {
            removeItem(name);
            removeId(id);
            removeTraining(id);
          }}
        />
      )}
    </div>
  );
};

export default TrainingTab;
