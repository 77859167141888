import { useEffect, useState, useMemo } from "react";
import { ReactComponent as XIcon } from "../../assets/icons/x-iconTwo.svg";
import { ReactComponent as BackIcon } from "../../assets/icons/left-icon.svg";
import axios from "axios";
import Loading from "../Loading/Loading.component";
import { Link } from "react-router-dom";
import AlertComponent from "../AlertComponent/AlertComponent";
import "./addDepartment.styles.css";

const AddDepartment = ({ showAddDept, setShowAddDept }) => {
  const [department, setDepartment] = useState("");

  const [pickedColor, setPickedColor] = useState("");

  const [status, setStatus] = useState("");

  const [successMss, setSuccessMss] = useState("");

  const [errMss, setErrMss] = useState("");

  const token = localStorage.getItem("token");

  const config = useMemo(
    () => ({
      headers: { Authorization: `Bearer ${token}` },
    }),
    [token]
  );

  const addDepartment = () => {
    axios
      .post(
        "/api/v1/department/create",
        {
          name: department,
          colorCode: pickedColor,
        },
        config
      )
      .then((res) => {
        setSuccessMss(res.data.message);
        setStatus(true);
        setTimeout(() => {
          setStatus("");
        }, 5000);
        setDepartment("");
      })
      .catch((error) => {
        console.log(error);
        setStatus(false);
        setErrMss(error.data.message);
        setTimeout(() => {
          setStatus("");
        }, 5000);
      });
  };

  const successText = "You've Successfully Added Department";
  const failedText = "Couldn't Add Department. Please Try Again";

  return (
    <div className="addDepartment__container">
      <div className="alert__container--div">
        <AlertComponent
          status={status}
          setStatus={setStatus}
          successText={successMss}
          failedText={errMss}
        />
      </div>
      <div className="addDepartment__content--container">
        <span className="add__dept--mobile-nav hide__mNav">
          <span
            style={{ display: "flex", alignItems: "center" }}
            onClick={() => setShowAddDept(!showAddDept)}
          >
            <BackIcon style={{ marginRight: "1.2rem" }} /> <p>Back</p>
          </span>
          <p style={{ fontSize: "1.4rem", fontWeight: "400" }}>Department</p>
        </span>
        <span className="addDept__title-and-close">
          <span className="addDept__texts">
            <h1 className="addDept__main--text">Create Department</h1>
            <p className="addDept__sub-text">Dept xyz</p>
          </span>
          <XIcon
            onClick={() => setShowAddDept(!showAddDept)}
            style={{ cursor: "pointer" }}
            className="hide__arrow"
          />
        </span>
        <div className="add__department--form">
          <label for="deptName" className="dept__input--label">
            Department Name
          </label>
          <input
            type="text"
            className="deptName__input"
            value={department}
            required
            onChange={(e) => setDepartment(e.target.value)}
            placeholder="e.g Product"
          />
        </div>
        <span className="color__tag-and-text">
          <p className="color__text">Pick a color tag (Optional)</p>
          <span className="color__tags">
            <div
              className="color__tag"
              id="#8E84FF"
              onClick={(e) => setPickedColor(e.target.id)}
              style={{
                background: "#8E84FF",
                cursor: "pointer",
                border:
                  pickedColor === "#8E84FF" ? "1.5px solid #6B6B6B" : "none",
              }}
            ></div>
            <div
              className="color__tag"
              id="#FFD951"
              onClick={(e) => setPickedColor(e.target.id)}
              style={{
                background: "#FFD951",
                cursor: "pointer",
                border:
                  pickedColor === "#FFD951" ? "1.5px solid #6B6B6B" : "none",
              }}
            ></div>
            <div
              className="color__tag"
              id="#FF9B52"
              onClick={(e) => setPickedColor(e.target.id)}
              style={{
                background: "#FF9B52",
                cursor: "pointer",
                border:
                  pickedColor === "#FF9B52" ? "1.5px solid #6B6B6B" : "none",
              }}
            ></div>
            <div
              className="color__tag"
              id="#E36EED"
              onClick={(e) => setPickedColor(e.target.id)}
              style={{
                background: "#E36EED",
                cursor: "pointer",
                border:
                  pickedColor === "#E36EED" ? "1.5px solid #6B6B6B" : "none",
              }}
            ></div>
            <div
              className="color__tag"
              id="#76C883"
              onClick={(e) => setPickedColor(e.target.id)}
              style={{
                background: "#76C883",
                cursor: "pointer",
                border:
                  pickedColor === "#76C883" ? "1.5px solid #6B6B6B" : "none",
              }}
            ></div>
          </span>
        </span>
        <button
          className="create__depts--CTA"
          onClick={() => addDepartment()}
          disabled={department ? false : true}
        >
          Create Department
        </button>
      </div>
    </div>
  );
};

export default AddDepartment;
