import { useEffect, useRef, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as CloseIcon } from "../../assets/icons/close-circleTwo.svg";
import { ReactComponent as FezzantLogo } from "../../assets/icons/fezzantLogo-main.svg";
import { ReactComponent as Barge } from "../../assets/icons/barge.svg";
import { ReactComponent as ShareIcon } from "../../assets/icons/share-iconTwo.svg";
import { ReactComponent as DownloadIcon } from "../../assets/icons/download-icon.svg";
import { ReactComponent as CeoSignature } from "../../assets/icons/ceo-signature.svg";
import axios from "axios";
import { Helmet } from "react-helmet";
import metaThumbnail from "../../assets/images/Congrats.png";
import Pdf from "react-to-pdf";

import MetaDecorator from "../MetaDecorator/MetaDecorator";
import "./sharedCert.styles.css";

const SharedCert = ({ showPassOrFail }) => {
  const [sharedCert, setSharedCert] = useState();

  const navigate = useNavigate();

  const certificateNo = useParams();

  const ref = useRef();

  const token = localStorage.getItem("token");

  const config = useMemo(() => {
    return {
      headers: { Authorization: `Bearer ${token}` },
    };
  }, [token]);

  const options = {
    orientation: "landscape",
    unit: "in",
    format: [4, 2],
  };

  useEffect(() => {
    axios
      .get(`/api/v1/certificate/view/${certificateNo}`, config)
      .then((res) => console.log(res));
  }, []);

  return (
    <div className="download__cert--container">
      <MetaDecorator
        title="Email Security expert coming through!"
        description="Hey! I just completed Fezzants engaging yet fun Email Security series and I'm now a certified email security expert. Check out my certificate!  Cool, innit?"
        imageDescription="Checkout my cool certificate from Fezzant!"
        imageUrl={metaThumbnail}
      />
      <div className="cert__container--download" ref={ref}>
        <div className="cert__content--container-down">
          <span className="logo-name-and-title">
            <FezzantLogo style={{ marginBottom: "1rem" }} />
            <p className="motto"> Securing your Human layer</p>
            <p className="cert_of_completion">Certificate of Completion</p>
            <h1 className="cert__course--title">[Series Name]</h1>
            <h1 className="cert__user--name">Mohammed Alli</h1>
          </span>
          <div className="cert__desc-and-barge">
            <span className="desc-and-sign">
              <p className="cert__desc">
                Has demonstrated a solid understanding and awareness of [Series
                Name]. In recognition for this achievement, Fezzant is pleased
                to award you this certificate.
              </p>
              <span className="sign__and__date">
                <span className="sign__and__name">
                  {/* <p className="signature"> */}
                  <CeoSignature className="signature" />
                  {/* </p> */}
                  <p className="cert__Ceo">Yaya Toure, CEO of Fezzant</p>
                </span>
                <span className="cert__date--span">
                  <span className="date__ofissuance--span">
                    <p className="cert__date">3rd of July, 2022</p>
                  </span>
                  <p className="cert__date--text">Certification Date</p>
                </span>
              </span>
            </span>
            <div className="barge">
              <Barge />
              <p className="cert__number">Fezzant.com/certificatephew003</p>
            </div>
          </div>
        </div>
      </div>

      <span className="download__cert-and-share--CTAs"></span>
    </div>
  );
};

export default SharedCert;
