import { useNavigate } from "react-router-dom";
import { ReactComponent as VideoIcon } from "../../assets/icons/video-icon.svg";
import { ReactComponent as ClockIcon } from "../../assets/icons/clock-icon.svg";
import { ReactComponent as CoinIcon } from "../../assets/icons/coin-icon.svg";
import { useAuthUserContext } from "../../contexts/AuthContext";
import ProgressBar from "../ProgressBar/ProgressBar.component";
import "./series.styles.css";

const Series = ({
  title,
  image,
  seriesId,
  points,
  content,
  percentageCompleted,
  duration,
  index,
  authDetails,
  // authUserData,
}) => {
  const navigate = useNavigate();

  const { authUserData } = useAuthUserContext();

  const url = "/company-panel/series-details/";

  const freeTrial = true;

  return (
    <div
      className={
        authUserData?.subInfo?.freetrial && index > 1
          ? "greyedOut"
          : "series__container"
      }
      style={{
        backgroundImage: `url(${image})`,
        backgroundSize: "cover",
      }}
      onClick={() => {
        navigate(`${url}${seriesId}`);
      }}
    >
      <div className="series__details">
        {/* <span className="title-and-points">
          <p className="series__title--text">{title}</p>
          <p className="series__points">
            {points} <CoinIcon style={{ marginLeft: "1.5rem" }} />
          </p>
        </span> */}
        <span className="scenarios-and-progress">
          <span className="videoCOunt__and__icon">
            <VideoIcon />
            <p className="videos__number">
              {content?.length} {content?.length <= 1 ? "Video" : "Videos"}
            </p>
          </span>
          <span className="videoDuration__and__icon">
            <ClockIcon />
            <p className="scenarios__number">{duration}</p>
          </span>
        </span>
        <span className="progressPercentage__and__bar">
          <p className="percentage__completed">{percentageCompleted}%</p>
          <ProgressBar done={percentageCompleted} />
        </span>
      </div>
    </div>
  );
};

export default Series;
