import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import axios from "axios";
import { AuthProvider } from "./contexts/AuthContext";
import { AllSeriesProvider } from "./contexts/AllSeriesContext";
// import { hydrate, render } from "react-dom";
import { SeriesDetailsProvider } from "./contexts/SeriesContext";
import { CertificateProvider } from "./contexts/CertificateContext";
import "./index.css";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const token = localStorage.getItem("token");

// axios.defaults.baseURL = "https://fezzant.herokuapp.com";
axios.defaults.baseURL = BASE_URL;

axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <SeriesDetailsProvider>
          <CertificateProvider>
            <AllSeriesProvider>
              <App />
            </AllSeriesProvider>
          </CertificateProvider>
        </SeriesDetailsProvider>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
