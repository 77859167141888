import { useNavigate } from "react-router-dom";
import { useAuthUserContext } from "../../contexts/AuthContext";
import { ReactComponent as VideoIcon } from "../../assets/icons/video-icon.svg";
import { ReactComponent as ClockIcon } from "../../assets/icons/clock-icon.svg";
import { ReactComponent as CoinIcon } from "../../assets/icons/coin-icon.svg";

import CircularProgressBar from "../CircularProgressBar/CircularProgressBar";
import "./mobileSeries.styles.css";

const MobileSeries = ({
  title,
  image,
  seriesId,
  points,
  content,
  duration,
  percentageCompleted,
  index,
  authDetails,
}) => {
  const navigate = useNavigate();

  const { authUserData } = useAuthUserContext();
  console.log(authUserData.type);
  const url = "/company-panel/series-details/";

  return (
    <div
      // className="mobile__series--container"
      className={
        authUserData.subInfo?.freetrial && index > 1
          ? "mobile__series--greyedOut"
          : "mobile__series--container"
      }
      onClick={() => {
        navigate(`${url}${seriesId}`);
      }}
    >
      <div
        className="series__image--div"
        style={{
          backgroundImage: `url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />

      <div className="series__details-and-iconss">
        <span className="video__quantity-and-progress">
          <span className="video__quantity">
            <VideoIcon />
            <p className="mobile__series-text">
              {" "}
              {content?.length} {content?.length <= 1 ? "Video" : "Videos"}
            </p>
          </span>
          <span className="video__prog">
            <CircularProgressBar percentage={percentageCompleted} />
          </span>
        </span>
        <span className="video__duration-and-point">
          <span className="video__dur">
            <ClockIcon />
            <p className="mobile__series-text">{duration}</p>
          </span>
          {/* <span className="video__pointss">
            <p className="pointss">200</p> <CoinIcon />
          </span> */}
        </span>
      </div>
    </div>
  );
};

export default MobileSeries;
