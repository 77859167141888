import { useMemo, useState } from "react";
import axios from "axios";
import VideoPlayer from "react-video-js-player";
import { ReactComponent as CloseCircle } from "../../assets/icons/closeCircle-icon.svg";
import "video.js/dist/video-js.css";
import mixpanel from "mixpanel-browser";
import ReactGA from "react-ga4";
import "./videoPlayer.styles.css";
import StartQuiz from "../StartQuiz/StartQuiz";
import QuestionComponent from "../QuestionComponent/QuestionComponent";
import FailedComponent from "../FailedComponent/Failed.component";
import { el } from "date-fns/locale";
import Passed from "../PassedComponent/PassedComponent.component";

mixpanel.init("f3574152559b2e61aa7f78dc5a2dee86", {
  debug: true,
});

const VideoPlayr = ({
  videoUrl,
  videoId,
  seriesId,
  toggleShowVideoPlayer,
  showVideoPlayer,
  thumbnail,
  seriesLength,
  videoIndex,
  videoTitle,
  showStartQuiz,
  checkSeriesStatus,
  seriesTitle,
  seriesIsCompleted,
}) => {
  const [showStartSeries, setShowStartSeries] = useState(false);
  const [questions, setQuesions] = useState([]);
  const [currentQuestionNum, setCurrentQuestionNum] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState();
  const [answers, setAnswers] = useState([]);

  //   printMe() {
  //     var printContents = document.getElementById("printDiv").innerHTML;
  //     var originalContents = document.body.innerHTML;
  //     document.body.innerHTML = printContents;
  //     window.print();
  //     document.body.innerHTML = originalContents;
  //   },
  //  id="printDiv"

  const token = localStorage.getItem("token");

  const config = useMemo(() => {
    return {
      headers: { Authorization: `Bearer ${token}` },
    };
  }, [token]);

  /////// Google analytics custom event functions
  const gAStartSeries = (seriesTitle) => {
    ReactGA.event({
      category: "START-SERIES",
      action: "START-SERIES",
      label: `${seriesTitle}`, // optional
      value: 99, // optional, must be a number
      nonInteraction: true, // optional, true/false
      transport: "xhr", // optional, beacon/xhr/image
    });
  };

  const gAStartContent = (seriesTitle, videoTitle) => {
    ReactGA.event({
      category: "START-CONTENT",
      action: "START-CONTENT",
      label: `Series - ${seriesTitle}, Video - ${videoTitle}`, // optional
      value: 99, // optional, must be a number
      nonInteraction: true, // optional, true/false
      transport: "xhr", // optional, beacon/xhr/image
    });
  };
  const gACompleteContent = (seriesTitle, videoTitle) => {
    ReactGA.event({
      category: "COMPLETE-CONTENT",
      action: "COMPLETE-CONTENT",
      label: `Series - ${seriesTitle}, Video - ${videoTitle}`, // optional
      value: 99, // optional, must be a number
      nonInteraction: true, // optional, true/false
      transport: "xhr", // optional, beacon/xhr/image
    });
  };

  const onVideoPlay = () => {
    if (videoId) {
      axios
        .put(`/api/v1/content/markOngoing/${videoId}`, {}, config)
        .then((res) => {
          console.log(res);
          if (videoIndex === 0) {
            // mixpanel.track("START-SERIES", {
            //   seriesTitle: `${seriesTitle}`,
            // });

            gAStartSeries(seriesTitle);
          }
          // mixpanel.track("START-CONTENT", {
          //   seriesTitle: `${seriesTitle}`,
          //   contentTitle: `${videoTitle}`,
          // });

          gAStartContent(seriesTitle, videoTitle);
        })
        .catch((error) => console.log(error));
    }
  };

  const onVideoEnd = () => {
    axios
      .put(`/api/v1/content/markCompleted/${videoId}`, {}, config)
      .then((res) => {
        checkSeriesStatus();
        // mixpanel.track("COMPLETE-CONTENT", {
        //   seriesTitle: `${seriesTitle}`,
        //   contentTitle: `${videoTitle}`,
        // });

        gACompleteContent(seriesTitle, videoTitle);
        console.log(res);
      })
      .catch((error) => console.log(error));
    axios
      .post(`/api/v1/learningmap/`, { config, seriesId: seriesId })
      .catch((error) => console.log(error));

    if (videoIndex === seriesLength) {
      setShowStartSeries(true);
    }
  };

  return (
    <div
      className={
        videoUrl ? "videoPlayer__container--two" : "videoPlayer__container--one"
      }
    >
      {videoUrl ? (
        <div className="video__component-and-close__icon">
          <CloseCircle
            className="close__videoPlayer"
            onClick={toggleShowVideoPlayer}
          />

          <VideoPlayer
            src={videoUrl}
            width="720"
            height="420"
            autoplay={true}
            onPlay={onVideoPlay}
            onEnd={onVideoEnd}
            seriesLength={seriesLength}
            videoIndex={videoIndex}
          />
        </div>
      ) : (
        <p>Select a video to start or continue the series!...</p>
      )}
    </div>
  );
};

export default VideoPlayr;
