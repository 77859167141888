import { useState } from "react";
import { useForm } from "react-hook-form";
import { ReactComponent as FezzantLogo } from "../../assets/icons/fezzant-logo.svg";
import { ReactComponent as MailLogo } from "../../assets/icons/mail-icon.svg";
import { ReactComponent as PersonIcon } from "../../assets/icons/person-icon.svg";
import { ReactComponent as EyeLogo } from "../../assets/icons/eye-icon.svg";
import { ReactComponent as EyeOffLogo } from "../../assets/icons/eye-off.svg";
import { ReactComponent as LockLogo } from "../../assets/icons/lock-icon.svg";
import Loading from "../Loading/Loading.component";

import "./register.styles.css";
import axios from "axios";

const Register = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("password");

  const togglePasswordType = () => {
    setShowPassword(!showPassword);
    setType(showPassword ? "text" : "password");
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    try {
      setLoading(true);
      axios
        .post("/api/v1/user", data)
        .then((response) => alert("User Added Successfully"));

      setLoading(false);
    } catch (error) {
      if (!error?.response) {
        setErrorMessage(
          "Unable to connect. Please Check Your Internet Connection."
        );
      } else if (error.response?.status === 400) {
        setErrorMessage("Incorrect Username or Password.");
      } else if (error.response?.state === 401) {
        setErrorMessage("unauthorized.");
      } else {
        setErrorMessage("Login Failed.");
      }
    }
    setLoading(false);
  };

  console.log(successMessage);

  return (
    <div className="register__container">
      {/* <pre>{JSON.stringify(info, undefined, 2)}</pre> */}
      <div className="register__content--container">
        <div className="register__form--container">
          <FezzantLogo className="fezzant__logo" />
          <div className="form-and-text__container">
            <span className="register__form--texts">
              {loading ? (
                <Loading type="cylon" color="rgb(101, 93, 138)" />
              ) : null}
              {successMessage ? (
                <span className="success__msg">User Added Successfully!</span>
              ) : null}
              <h2 className="register__formheading">Register</h2>
              {errorMessage ? (
                <p
                  style={{
                    color: "#F95959",
                    marginBottom: "1rem",
                  }}
                >
                  {errorMessage}
                </p>
              ) : (
                ""
              )}
              <p className="register__form-sub--text">
                Please fill the fields below to create your account.
              </p>
            </span>
            <form
              action="
                "
              onSubmit={handleSubmit(onSubmit)}
              className="register__form"
            >
              <label htmlFor="Name" className="register__email--label">
                Name
              </label>
              <input
                type="
              text"
                name="name"
                className="register__email--input"
                {...register("name", { required: true })}
              />
              {errors.name && (
                <p
                  style={{
                    color: "#F95959",
                    marginTop: "1rem",
                  }}
                >
                  Name is required
                </p>
              )}
              <PersonIcon className="register__name--icon" />
              <label htmlFor="Email" className="register__email--label">
                Email Address
              </label>
              <div className="register__email-and-icon">
                <input
                  type="
              email"
                  name="email"
                  className="register__email--input"
                  {...register("email", {
                    required: true,
                    // pattern: {
                    //   value: /^\S+@\s+$/i,
                    //   message: "Please enter a valid email",
                    // },
                  })}
                />
                {errors.email && (
                  <p
                    style={{
                      color: "#F95959",
                      marginTop: "1rem",
                    }}
                  >
                    Email is required
                  </p>
                )}
                <MailLogo className="register__mail--icon" />
              </div>
              <label htmlFor="password" className="register__password--label">
                Password
              </label>
              <div className="register__password-and-icons">
                <input
                  type={type}
                  name="password"
                  className="register__password--input"
                  {...register("password", {
                    required: true,
                    // minLength: {
                    //   value: 8,
                    //   message: "Password must be at least 8 characters",
                    // },
                  })}
                />
                {errors.password && (
                  <p
                    style={{
                      color: "#F95959",
                      marginTop: "1rem",
                      marginBottom: "2rem",
                    }}
                  >
                    Password is required
                  </p>
                )}
                {type === "password" ? (
                  <EyeLogo
                    className="register__eye--icon"
                    onClick={togglePasswordType}
                  />
                ) : (
                  <EyeOffLogo
                    className="register__eye--icon"
                    onClick={togglePasswordType}
                  />
                )}
                {/* <EyeLogo className="register__eye--icon " /> */}
                <LockLogo className="register__lock--icon" />
              </div>
              <button className="register__submit--CTA">Register</button>
            </form>
          </div>
        </div>
        <div className="register__image--container">
          <div className="register__image--box">
            <img
              src={require("../../assets/images/booking-image.png")}
              alt="register__image"
            />
          </div>
          <h1 className="register__main--text">
            Designed to protect against
            <br />
            cyberattacks
          </h1>
          <p className="register__sub--text">
            Create an account to get started today!
          </p>
        </div>
      </div>
    </div>
  );
};

export default Register;
