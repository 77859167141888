import { useState } from "react";
import { ReactComponent as FezzantLogo } from "../../assets/icons/fezzant-beta.svg";
import { useAuthContext } from "../../contexts/AuthContext";
import { useAuthUserContext } from "../../contexts/AuthContext";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { ReactComponent as LogoutIcon } from "../../assets/icons/logout-icon.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/close-circle.svg";
import { ReactComponent as OffIcon } from "../../assets/icons/off-icon.svg";

import "./sidebar.styles.css";

const SideBar = ({
  adminSideideBarLinks,
  staffSideideBarLinks,
  logOutPrompt,
  setLogOutPropmt,
}) => {
  const { setAuthDetails } = useAuthContext();

  const { authUserData } = useAuthUserContext();

  let id = 30;

  const navigate = useNavigate();

  const sideBarLinks =
    authUserData.user?.type === "admin"
      ? adminSideideBarLinks
      : staffSideideBarLinks;

  return (
    <div className="sidebar__container">
      {logOutPrompt ? (
        <div className="logout__prompt--container">
          <div className="logout__prompt--content">
            <span className="cancel__logout--icon">
              <CloseIcon
                style={{ cursor: "pointer" }}
                onClick={() => setLogOutPropmt(false)}
              />
            </span>
            <div className="logout__icon-and-CTAs">
              <OffIcon />
              <p className="logout__prompt--text">
                Are you sure you want to log out?
              </p>
              <span className="logout__prompts--CTAs">
                <button
                  className="yes__logout"
                  onClick={() => {
                    setAuthDetails("");
                    localStorage.clear();
                    navigate("/");
                  }}
                >
                  Yes, Log me out
                </button>
                <button
                  className="cancel__logout"
                  onClick={() => setLogOutPropmt(false)}
                >
                  Cancel
                </button>
              </span>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <span className="dashboard__fezzant--logo-span hidden">
        <FezzantLogo className="hidden" />
      </span>
      <span className="auth__company--logo hidden">
        {authUserData?.companyName}
      </span>
      <div className="sidebar__content--container hidden">
        <div className="sidebar__inner--content">
          <ul className="side__nav--links">
            {sideBarLinks.map((link) => {
              return (
                <NavLink
                  to={`${link.urlLink}`}
                  className={({ isActive }) =>
                    isActive ? "active" : "link__and__icon"
                  }
                  key={id++}
                >
                  <span className="colored">
                    {link.urlIcon} <li className="link">{link.urlName}</li>
                  </span>

                  <span className="notColored">
                    {link.urlIconTwo} <li className="link">{link.urlName}</li>{" "}
                  </span>
                </NavLink>
              );
            })}
          </ul>
        </div>
        {/* <Link to="/"> */}
        {/* </Link> */}
        <span
          className="link__and__icon logout__CTA"
          onClick={() => {
            setLogOutPropmt(true);
          }}
        >
          <LogoutIcon />
          <p
            style={{
              color: "#858585",
              fontSize: "1.2rem",
            }}
          >
            Log out
          </p>
        </span>
      </div>
      <div className="mobile__nav">
        <span className="nav-links">
          <ul className="links">
            {sideBarLinks.map((link) => {
              return (
                <NavLink
                  to={`${link.urlLink}`}
                  className={({ isActive }) =>
                    isActive ? "active" : "link__and__icon"
                  }
                  key={id++}
                >
                  <span className="colored">
                    <span>{link.urlIcon}</span>{" "}
                    <li className="link">{link.urlName}</li>
                  </span>

                  <span className="notColored">
                    {link.urlIconTwo} <li className="link">{link.urlName}</li>{" "}
                  </span>
                </NavLink>
              );
            })}
          </ul>
        </span>
      </div>
    </div>
  );
};

export default SideBar;
