import { useState, useRef, useMemo, useEffect } from "react";
import { ReactComponent as FezzantLogo } from "../../assets/icons/fezzant-beta.svg";
import { ReactComponent as MailLogo } from "../../assets/icons/mail-icon.svg";
import { ReactComponent as NameIcon } from "../../assets/icons/name-icon.svg";
import axios from "axios";
import { ReactComponent as XIcon } from "../../assets/icons/x-svg.svg";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Loading from "../Loading/Loading.component";
import SearchFilter from "../SearchFilter/SearchFilter.component";
import { ReactComponent as BackIcon } from "../../assets/icons/left-icon.svg";
import TrainingTab from "../TrainingTab/TrainingTab.component";
import AlertComponent from "../AlertComponent/AlertComponent";
import "./editDepartment.styles.css";

const ADDUSERURL = "/api/v1/business/adduser";

const EditDepartment = ({
  setShowAddUserForm,
  showEditDept,
  setShowEditDept,
  deptmntToEdit,
  allSeries,
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);
  const [deptName, setDeptName] = useState("");
  const [status, setStatus] = useState("");

  const [successMss, setSuccessMss] = useState("");

  const [errMss, setErrMss] = useState("");
  const [showDropDown, setShowDropDown] = useState(false);
  const [success, setSuccess] = useState(false);
  const [pickedColor, setPickedColor] = useState("");
  const [selectedTrainings, setSelectedTrainings] = useState([]);
  const [selectedTrainingsIds, setSelectedTrainingsIds] = useState([]);
  const [deptDetails, setDeptDetails] = useState();

  const emailRef = useRef();

  const [selectedUsers, setSelectedUsers] = useState([]);

  const token = localStorage.getItem("token");

  const config = useMemo(() => {
    return {
      headers: { Authorization: `Bearer ${token}` },
    };
  }, [token]);

  useEffect(() => {
    getDepartment();
    // setPickedColor(deptmntToEdit.colorCode);
  }, [showEditDept]);

  console.log({ deptmntToEdit });
  const getDepartment = () => {
    axios
      .get(`/api/v1/department/getdepartment/${deptmntToEdit[0].id}`, config)
      .then((res) => {
        setDeptDetails(res.data);
        setSelectedTrainings(res.data?.series);
        setDeptName(res.data?.department?.name);
        console.log(res.data);
      })
      .catch((err) => console.log(err));
  };

  console.log({ deptDetails });
  console.log({ deptName });

  const addSelectedTraining = (trainingAdded) => {
    const filteredTrainings = selectedTrainings?.filter(
      (training) => training.title === trainingAdded.title
    );
    if (filteredTrainings.length) {
      console.log({ filteredTrainings });
      return;
    } else {
      setSelectedTrainings([...selectedTrainings, trainingAdded]);
    }
  };
  const addSelectedTrainingId = (trainingId) => {
    const filteredTrainingId = selectedTrainingsIds?.filter(
      (id) => id === trainingId
    );
    if (filteredTrainingId.length) {
      console.log({ filteredTrainingId });
      return;
    } else {
      setSelectedTrainingsIds([...selectedTrainingsIds, trainingId]);
    }
  };

  const removeItem = (trainingToRemove) => {
    setSelectedTrainings(
      selectedTrainings.filter(
        (training) => training.title !== trainingToRemove
      )
    );
    console.log("clicked");
  };
  const removeId = (trainingToRemove) => {
    setSelectedTrainingsIds(
      selectedTrainingsIds.filter((id) => id !== trainingToRemove)
    );
    console.log("clicked");
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    //if button enabled with JS hack

    axios
      .put(
        "/api/v1/department/editDept",
        {
          departmentId: deptmntToEdit[0].id,
          name: deptName,
          colorCode: pickedColor,
        },
        config
      )
      .then((res) => {
        setSuccessMss(res.data.message);
        setStatus(true);
        console.log(res);
        getDepartment();
      })
      .catch((err) => {
        console.log(err);
        setErrMss(err.data.message);
        setStatus(false);
      });
  };

  const noRemoveIcon = true;

  return (
    <div
      className="editDeptForm__container"
      onClick={() => (showDropDown === true ? setShowDropDown(false) : null)}
    >
      <div className="addUserForm__container ">
        <div className="alert__container--div">
          <AlertComponent
            status={status}
            setStatus={setStatus}
            successText={successMss}
            failedText={errMss}
          />
        </div>
        <div className="addUserForm__form--container edit__form--container edit__mobile--height">
          {/* <span className="addUser__logo-span">
          <FezzantLogo />
        </span> */}
          <div className="add-user__form-and-text__container">
            <span className="add__dept--mobile-nav hide__mNav">
              <span
                style={{ display: "flex", alignItems: "center" }}
                onClick={() => setShowEditDept(false)}
              >
                <BackIcon style={{ marginRight: "1.2rem" }} /> <p>Back</p>
              </span>
              <p style={{ fontSize: "1.4rem", fontWeight: "400" }}>
                Edit Department
              </p>
            </span>
            <span className="addUser__heading-and-close--CTA">
              <span className="addUserForm__form--texts">
                {loading ? (
                  <Loading type="cylon" color="rgb(101, 93, 138)" />
                ) : null}

                <h2 className="addUserForm__formheading">Edit Department</h2>

                {errorMessage ? (
                  <p
                    style={{
                      color: "#F95959",
                      marginBottom: "1rem",
                    }}
                  >
                    {errorMessage}
                  </p>
                ) : (
                  ""
                )}
                <p className="addUserForm__form-sub--text">Dept xyz</p>
              </span>
              {/* <Link to="/company-panel/users"> */}
              <XIcon
                style={{ fill: "#F95959", cursor: "pointer" }}
                onClick={() => setShowEditDept(!showEditDept)}
                className="hide__arrow"
              />
              {/* </Link> */}
            </span>
            <form
              action="
                "
              className="addUserForm__form"
              onSubmit={handleSubmit}
            >
              <label htmlFor="Email" className="addUserForm__email--label">
                Department Name
              </label>
              <div className="addUserForm__email-and-icon">
                <input
                  type="text"
                  id="department"
                  ref={emailRef}
                  autoComplete="off"
                  onChange={(e) => setDeptName(e.target.value)}
                  value={deptName}
                  required
                  aria-describedby="uidnote"
                  onFocus={() => setEmailFocus(true)}
                  onBlur={() => setEmailFocus(false)}
                  className={
                    errorMessage
                      ? "email--input"
                      : "createCompLogin__email--input"
                  }
                  style={{
                    borderRadius: "1rem",
                    paddingLeft: "2rem",
                    width: "100%",
                  }}
                />
                {errorMessage && (
                  <p
                    style={{
                      color: "#F95959",
                      marginTop: "1rem",
                    }}
                  >
                    Department name is required
                  </p>
                )}
                {/* <MailLogo className="mail__icon" /> */}
              </div>
              {/* <label
                htmlFor="firstName"
                className="addUserForm__password--label"
              >
                Add or Remove Training
              </label>
              <SearchFilter
                showDropDown={showDropDown}
                setShowDropDown={setShowDropDown}
                width="100"
                addSelectedTraining={addSelectedTraining}
                addSelectedTrainingId={addSelectedTrainingId}
                allSeries={allSeries}
              /> */}

              {/* <span className="assigned__trainings">
                {selectedTrainings?.map((training) => (
                  <TrainingTab
                    name={training.title}
                    id={training.id}
                    key={uuidv4()}
                    removeItem={removeItem}
                    removeId={removeId}
                    removeTraining={removeTraining}
                  />
                ))}
     
              </span> */}

              <p
                className="members__heading--text"
                style={{ margin: "2.5rem 0 1rem 0" }}
              >
                Members
              </p>
              <span className="assigned__trainings">
                {deptmntToEdit[0].users.length
                  ? deptmntToEdit[0].users?.map((user) => (
                      <TrainingTab
                        name={user?.name}
                        userId={user.id}
                        removeItem={removeItem}
                        noRemoveIcon={noRemoveIcon}
                      />
                    ))
                  : null}
                {/* <TrainingTab name="Mosh Hamedani" />
                  <TrainingTab name="Andrei Neagioe" />
                  <TrainingTab name="Andrei Neagioe" />
                  <TrainingTab name="Andrei Neagioe" /> */}
              </span>

              <p
                className="colortag__heading--text"
                style={{ marginBottom: "1rem" }}
              >
                Color Tags
              </p>
              <span className="color__tag-and-text">
                {/* <p className="color__text">Pick a color tag (Optional)</p> */}
                <span className="color__tags">
                  <div
                    className="color__tag"
                    id="#8E84FF"
                    onClick={(e) => setPickedColor(e.target.id)}
                    style={{
                      background: "#8E84FF",
                      cursor: "pointer",
                      border:
                        pickedColor === "#8E84FF"
                          ? "1.5px solid #6B6B6B"
                          : "none",
                    }}
                  ></div>
                  <div
                    className="color__tag"
                    id="#FFD951"
                    onClick={(e) => setPickedColor(e.target.id)}
                    style={{
                      background: "#FFD951",
                      cursor: "pointer",
                      border:
                        pickedColor === "#FFD951"
                          ? "1.5px solid #6B6B6B"
                          : "none",
                    }}
                  ></div>
                  <div
                    className="color__tag"
                    id="#FF9B52"
                    onClick={(e) => setPickedColor(e.target.id)}
                    style={{
                      background: "#FF9B52",
                      cursor: "pointer",
                      border:
                        pickedColor === "#FF9B52"
                          ? "1.5px solid #6B6B6B"
                          : "none",
                    }}
                  ></div>
                  <div
                    className="color__tag"
                    id="#E36EED"
                    onClick={(e) => setPickedColor(e.target.id)}
                    style={{
                      background: "#E36EED",
                      cursor: "pointer",
                      border:
                        pickedColor === "#E36EED"
                          ? "1.5px solid #6B6B6B"
                          : "none",
                    }}
                  ></div>
                  <div
                    className="color__tag"
                    id="#76C883"
                    onClick={(e) => setPickedColor(e.target.id)}
                    style={{
                      background: "#76C883",
                      cursor: "pointer",
                      border:
                        pickedColor === "#76C883"
                          ? "1.5px solid #6B6B6B"
                          : "none",
                    }}
                  ></div>
                </span>
              </span>

              <button className="editDeptForm__submit--CTA" onClick={() => {}}>
                Make Changes
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditDepartment;
