import { useState, useMemo } from "react";
import { ReactComponent as TrashIcon } from "../../assets/icons/trash-icon.svg";
import { ReactComponent as MoreIcon } from "../../assets/icons/more-vertical.svg";
import { ReactComponent as XIcon } from "../../assets/icons/x-iconTwo.svg";
import axios from "axios";
import "./changeUserDept.styles.css";
import SearchFilter from "../SearchFilter/SearchFilter.component";
import AlertComponent from "../AlertComponent/AlertComponent";

const ChangeUserDept = ({
  departments,
  userId,
  showChangeDept,
  setShowChangeDept,
}) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const [departmentId, setDepartmentId] = useState("");
  const [status, setStatus] = useState("");
  const [successMss, setSuccessMss] = useState("");
  const [errMss, setErrMss] = useState("");

  const token = localStorage.getItem("token");

  const config = useMemo(
    () => ({
      headers: { Authorization: `Bearer ${token}` },
    }),
    [token]
  );

  const changeUserDept = () => {
    axios
      .put(
        "/api/v1/department/changeuserdepartment",
        { userId: userId, departmentId: departmentId },
        config
      )
      .then((res) => {
        console.log(res);
        setSuccessMss("User's department updated successfully");
        setStatus(true);
      })
      .catch((err) => {
        console.log(err);
        setErrMss("Error changing user's department");
        setStatus(false);
      });
  };

  console.log({ userId });
  return (
    <div className="change__userDept-container">
      <div className="alert__container--div">
        <AlertComponent
          status={status}
          setStatus={setStatus}
          successText={successMss}
          failedText={errMss}
        />
      </div>
      <div className="change__userDept-content">
        <div className="change__dept-input--container">
          <span className="changeDept__heading-and-closeIcon">
            <p className="changeDept__heading">Change Department</p>
            <XIcon
              onClick={() => setShowChangeDept(!showChangeDept)}
              style={{ cursor: "pointer" }}
            />
          </span>
          <span className="dept__input-and-label">
            <label>Department</label>
            <SearchFilter
              showDropDown={showDropDown}
              setShowDropDown={setShowDropDown}
              departments={departments}
              width="100"
              setDepartmentId={setDepartmentId}
            />
          </span>
          <button
            className="change__dept--CTA"
            onClick={() => changeUserDept()}
          >
            Move to new department
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChangeUserDept;
