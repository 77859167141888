import { useState, useEffect, useMemo } from "react";
import { ReactComponent as RemoveIcon } from "../../assets/icons/remove-icon.svg";
import { ReactComponent as LeftIcon } from "../../assets/icons/left-icon.svg";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Loading from "../Loading/Loading.component";
import "./userPage.styles.css";
import { toast } from "react-toastify";

const UserPage = () => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});
  const [removeUser, setRemoveUser] = useState(false);

  const toggleRemoveUser = () => {
    setRemoveUser(!removeUser);
  };

  const navigate = useNavigate();

  const deleteUser = () => {
    axios
      .delete(`/api/v1/business/deleteuser/${user.id}`, config)
      .then((res) => {
        toast("User Removed");
        setTimeout(() => {
          navigate(-1);
        }, 3000);
      })
      .catch((error) => console.log(error));
  };

  const param = useParams();

  const userId = param.id;

  const token = localStorage.getItem("token");

  const config = useMemo(() => {
    return {
      headers: { Authorization: `Bearer ${token}` },
    };
  }, [token]);

  const nth = function (d) {
    if (d > 3 && d < 21) return "th";
    switch (d % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  console.log();

  const d = new Date(user.createdAt?.substr(0, 10));
  let month = months[d.getMonth()];
  const year = d.getFullYear();
  const day = d.getDate();
  const formatedCreatedAt = `${day}${nth(day)} of ${month}, ${year}`;
  console.log(formatedCreatedAt);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`/api/v1/business/getuser/${userId}`, config)
      .then((res) => {
        setUser(res.data.user);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  const str = `${user.name?.toUpperCase()}`;
  const matches = str.match(/\b(\w)/g);
  const acronym = matches.join("");
  return (
    <div className="user__page--container">
      {loading ? <Loading /> : null}

      <h1 className="user__page--heading hide__title">User Details</h1>
      <LeftIcon className="show__arrow" onClick={() => navigate(-1)} />
      <div className="user__deatails--container">
        <div className="user__details--content">
          <div className="user__details--box">
            <span className="image__and__text--details-span">
              <span className="user__picture--container">
                <span className="profile__image--span">
                  {/* <img
                    src="https://upload.wikimedia.org/wikipedia/en/d/dc/MichaelScott.png"
                    alt=""
                    className="user__profile--picture"
                  /> */}
                  <span className="user__profile--image">{acronym}</span>
                </span>
              </span>
              <span className="user__text--details">
                <h2 className="user__profile--name">{user.name}</h2>
                <p className="email__label">Email</p>
                <p className="user__email--add">{user.email}</p>
                <p className="invitation__date">Invitation Date</p>
                <p className="invitation__date--num">{formatedCreatedAt}</p>
              </span>
            </span>
            <span
              className={
                user.status === "pending"
                  ? "user__profile--status-pending"
                  : "user__profile--status-joined"
              }
            >
              {user.status === "pending" ? "Invited" : "Joined"}
            </span>
          </div>
          <span className="remove__user--span">
            {removeUser ? (
              <div className="remove__prompt--con">
                <p className="remove__prompt--text">
                  Are you sure you want to
                  <br />
                  remove this user?
                </p>
                <span className="remove__action--CTAs">
                  <button className="remove__action--CTA" onClick={deleteUser}>
                    Yes, Remove
                  </button>
                  <button
                    className="cancel__CTA"
                    onClick={() => setRemoveUser(!removeUser)}
                  >
                    Cancel
                  </button>
                </span>
              </div>
            ) : null}
            <button className="remove__user--CTA" onClick={toggleRemoveUser}>
              Remove User{" "}
              <RemoveIcon
                style={{
                  marginLeft: "1rem",
                }}
              />
            </button>
          </span>
        </div>
      </div>
    </div>
  );
};

export default UserPage;
