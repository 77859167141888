import { useEffect, useState, useMemo } from "react";
import { ReactComponent as Previous } from "../../assets/icons/previous-arrow.svg";
import { ReactComponent as Next } from "../../assets/icons/next-arrow.svg";
import { ReactComponent as NoDeptIcon } from "../../assets/icons/no-department-icon.svg";
import { ReactComponent as AddUser } from "../../assets/icons/addUser.svg";
import axios from "axios";
import Loading from "../Loading/Loading.component";
import UsersTableData from "../UsersTableData/UsersTableData.component";
import { Link } from "react-router-dom";
import AddUserForm from "../AddUserForm/AddUserForm";
import AddDepartment from "../AddDepartment/AddDepartment.component";
import AddUserToDept from "../AddUserToDept/AddUserToDept.component";
import AssignTraining from "../AssignTraining/AssignTraining.component";
import EditDepartment from "../EditDepartment/EditDepartment.component";
import AlertComponent from "../AlertComponent/AlertComponent";
import ChangeUserDept from "../ChangeUserDept/ChangeUserDept.component";
import GAMetaDecorator from "../GAMetaDecorator/GAMetaDecorator";
import "./users.styles.css";

const Users = () => {
  const [usersData, setUsersData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [showAddUserForm, setShowAddUserForm] = useState(false);
  const [showAddDept, setShowAddDept] = useState(false);
  const [showEditDept, setShowEditDept] = useState(false);
  const [showAssignTraining, setShowAssignTraining] = useState(false);
  const [showChangeDept, setShowChangeDept] = useState(false);
  const [userId, setUserId] = useState("");
  const [status, setStatus] = useState("");
  const [successMss, setSuccessMss] = useState("");
  const [errMss, setErrMss] = useState("");
  const [deptToEdit, setDeptToEdit] = useState("");
  const [deptForAssign, setDeptForAssign] = useState("");
  const [allSeries, setAllSeries] = useState("");

  const token = localStorage.getItem("token");

  const config = useMemo(
    () => ({
      headers: { Authorization: `Bearer ${token}` },
    }),
    [token]
  );

  useEffect(() => {
    axios
      .get("/api/v1/department/getdepartmentsinbusiness", config)
      .then((res) => {
        setDepartments(res.data?.departments);
        console.log(res);
        console.log({ departments });
      });

    // setLoading(true);
    // axios
    //   .get("/api/v1/business/getstaff", config)
    //   .then((res) => {
    //     setUsersData(res.data?.staff);
    //     setLoading(false);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     setLoading(false);
    //   });
  }, [showAddDept, showAddUserForm, showEditDept, showChangeDept]);

  useEffect(() => {
    setLoading(true);
    axios
      .get("/api/v1/series", config)
      .then((res) => {
        console.log(res);
        setAllSeries(res.data.series);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);

        setLoading(false);
      });
  }, [config]);

  console.log({ departments });

  const getAllDepartments = () => {};

  const deptmntToEdit = departments.filter(
    (department) => department.id === deptToEdit
  );

  console.log({ deptmntToEdit });

  return (
    <>
      <GAMetaDecorator
        title="Users - Page"
        description="Fezzant's dashboard login page"
      />
      <div className="users__container">
        {/* <div className="alert__container--div">

         <AlertComponent
          status={status}
          setStatus={setStatus}
          successText={successMss}
          failedText={errMss}
        />
         </div> */}
        {showAddDept ? (
          <AddDepartment
            showAddDept={showAddDept}
            setShowAddDept={setShowAddDept}
          />
        ) : null}
        {showAssignTraining ? (
          <AssignTraining
            showAssignTraining={showAssignTraining}
            setShowAssignTraining={setShowAssignTraining}
            deptForAssign={deptForAssign}
            allSeries={allSeries}
          />
        ) : null}
        {showEditDept ? (
          <EditDepartment
            showEditDept={showEditDept}
            setShowEditDept={setShowEditDept}
            deptmntToEdit={deptmntToEdit}
            allSeries={allSeries}
          />
        ) : null}
        {!departments.length ? (
          <div className="get__started__container">
            <NoDeptIcon />
            <p className="add__department--text">
              You have no departments yet.
              <br />
              Get started to add a department.
            </p>
            <button
              className="get__started--CTA"
              onClick={() => setShowAddDept(!showAddDept)}
            >
              Get Started
            </button>
          </div>
        ) : (
          <AddUserToDept
            setShowAddUserForm={setShowAddUserForm}
            departments={departments}
            showAddDept={showAddDept}
            setShowAddDept={setShowAddDept}
            showEditDept={showEditDept}
            setShowEditDept={setShowEditDept}
            showAssignTraining={showAssignTraining}
            setShowAssignTraining={setShowAssignTraining}
            setUsersData={setUsersData}
            setDeptToEdit={setDeptToEdit}
            setDeptForAssign={setDeptForAssign}
          />
        )}
        {showAddUserForm ? (
          <AddUserForm
            setShowAddUserForm={setShowAddUserForm}
            showAddUserForm={showAddUserForm}
            departments={departments}
          />
        ) : null}
        {showChangeDept ? (
          <ChangeUserDept
            showChangeDept={showChangeDept}
            setShowChangeDept={setShowChangeDept}
            departments={departments}
            userId={userId}
          />
        ) : null}
        {/* {loading ? <Loading type="cylon" color="rgb(101, 93, 138)" /> : null} */}
        <>
          {departments.length ? (
            <>
              <span className="users__title-and-CTA">
                <h1 className="users__heading">Users</h1>
                <span className="add__user--CTA-span">
                  {/* <button
            className="add__user-CTA"
            onClick={() => setShowAddUserForm(!showAddUserForm)}
          >
            <AddUser /> <p style={{ marginLeft: ".8rem" }}>Add User</p>
          </button> */}
                </span>
              </span>
              <table className="users__table">
                <thead>
                  <tr className="state__heading">
                    <th className="user__table--heading sn__heading">#</th>
                    <th className="user__table--heading name__heading">Name</th>
                    <th className="user__table--heading">Email</th>
                    <th className="user__table--heading state__heading">
                      Status
                    </th>
                    <th className="user__table--heading">Date Joined</th>
                  </tr>
                </thead>

                {usersData?.map((user, index) => {
                  return (
                    <UsersTableData
                      key={user.id}
                      position={index}
                      name={user.name}
                      email={user.email}
                      status={user.status}
                      dateJoined={user.updatedAt}
                      userId={user.id}
                      showChangeDept={showChangeDept}
                      setShowChangeDept={setShowChangeDept}
                      setUserId={setUserId}
                    />
                  );
                })}
              </table>{" "}
            </>
          ) : null}
        </>
        {/* 
      <span
        className="pagination__span
      "
      >
        <span className="rows__and__num">
          <span className="rows">
            <p className="rowsperpage">Rows per page</p>
            <p className="row__num">5</p>
          </span>
        </span>
        <span className="page__indicator_and__control">
   
          <p className="prev">Previous</p>
          <span className="prev__next--control">
            <Previous /> <Next className="next__arrow" />
          </span>
          <p className="next">Next</p>
        </span>
      </span> */}
      </div>
    </>
  );
};

export default Users;
