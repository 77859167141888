import React, { useState, useContext, createContext } from "react";

const CertificateContext = createContext();

export const useCertificateContext = () => {
  return useContext(CertificateContext);
};

export const CertificateProvider = ({ children }) => {
  const [certificateData, setCertificateData] = useState();

  return (
    <CertificateContext.Provider
      value={{ certificateData, setCertificateData }}
    >
      {children}
    </CertificateContext.Provider>
  );
};
