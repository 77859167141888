// import { Chart, ArcElement } from "chart.js";
import "chart.js/auto";
import { Doughnut } from "react-chartjs-2";

import "./activityChart.styles.css";

const ActivityChart = ({ data, options, name }) => {
  return (
    <div className="activity__chart--box">
      <div className="activity__chart">
        <Doughnut data={data} options={options} />
        <p className="activity__title">{name}</p>
      </div>
    </div>
  );
};

export default ActivityChart;
