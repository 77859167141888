import { useCallback, useEffect, useMemo, useState } from "react";
import { ReactComponent as FilmRoll } from "../../assets/icons/video-iconTwo.svg";
// import { ReactComponent as ClockIcon } from "../../assets/icons/clock-icon.svg";
import Video from "../VideoComponent/Video.component";
import { useParams, useNavigate } from "react-router-dom";
import VideoPlayer from "../VideoPlayer/VideoPlayer.component";
import axios from "axios";
import mixpanel from "mixpanel-browser";
import { useSeriesDetailsContext } from "../../contexts/SeriesContext";
import { useCertificateContext } from "../../contexts/CertificateContext";
import Loading from "../Loading/Loading.component";
import { ReactComponent as ExpiredIcon } from "../../assets/icons/expired-icon.svg";
import ProgressBar from "../ProgressBar/ProgressBar.component";
import StartQuiz from "../StartQuiz/StartQuiz";
import OverlayPrompt from "../OverlayPrompt/OverlayPrompt.component";
import QuestionComponent from "../QuestionComponent/QuestionComponent";
import Passed from "../PassedComponent/PassedComponent.component";
import FailedComponent from "../FailedComponent/Failed.component";
import SeriesInfo from "../SeriesInfo/SeriesInfo.component";
import GAMetaDecorator from "../GAMetaDecorator/GAMetaDecorator";
import ReactGA from "react-ga4";

import "./seriesDetails.styles.css";

mixpanel.init("f3574152559b2e61aa7f78dc5a2dee86", {
  debug: true,
});

const SeriesDetails = () => {
  const [singleSeries, setSingleSeries] = useState({});

  const [videoUrl, setVideoUrl] = useState("");

  const [videoId, setVideoId] = useState("");

  const [videoTitle, setVideoTitle] = useState("");

  const [videoIndex, setVideoIndex] = useState("");

  const [loading, setLoading] = useState(false);

  const [showStartQuiz, setShowStartQuiz] = useState(false);

  const [showQuestion, setShowQuestion] = useState(false);

  const [showResult, setShowResult] = useState(false);

  const [showVideoPlayer, setShowVideoPlayer] = useState(false);

  const [seriesIsCompleted, setSeriesIsCompleted] = useState(false);

  const [percentageComp, setPercentageComp] = useState(false);

  const [quizQuestion, setQuizQuestion] = useState([]);

  const [currentQuestionNum, setCurrentQuestionNum] = useState(0);

  const [current, setCurrent] = useState();

  const [currentQuestionId, setCurrentQuestionId] = useState("");

  const [selectedAnswer, setSelectedAnswer] = useState("");

  const [result, setResult] = useState();

  const [showSubmitPropmt, setShowSubmitPrompt] = useState(false);

  const [answers, setAnswers] = useState([]);

  const { id } = useParams();

  const { certificateData, setCertificateData } = useCertificateContext();

  const token = localStorage.getItem("token");

  const config = useMemo(() => {
    return {
      headers: { Authorization: `Bearer ${token}` },
    };
  }, [token]);

  /////// Google analytics custom event functions
  const gAStartSeries = (seriesTitle) => {
    ReactGA.event({
      category: "START-SERIES",
      action: "START-SERIES",
      label: `${seriesTitle}`, // optional
      value: 99, // optional, must be a number
      nonInteraction: true, // optional, true/false
      transport: "xhr", // optional, beacon/xhr/image
    });
  };
  const gAQuizStarted = (seriesTitle) => {
    ReactGA.event({
      category: "QUIZ-STARTED",
      action: "QUIZ-STARTED",
      label: `${seriesTitle}`, // optional
      value: 99, // optional, must be a number
      nonInteraction: true, // optional, true/false
      transport: "xhr", // optional, beacon/xhr/image
    });
  };

  const gAQuizCompleted = (seriesTitle) => {
    ReactGA.event({
      category: "QUIZ-COMPLETED",
      action: "QUIZ-COMPLETED",
      label: `${seriesTitle}`, // optional
      value: 99, // optional, must be a number
      nonInteraction: true, // optional, true/false
      transport: "xhr", // optional, beacon/xhr/image
    });
  };

  const getQuestions = useCallback(() => {
    axios.get(`/api/v1/question/getquestions/${id}`, config).then((res) => {
      setQuizQuestion(res.data);
      setCurrent(res.data?.questions[currentQuestionNum]);
      console.log({ quizQuestion });
    });
  }, [config, currentQuestionNum, id, quizQuestion]);

  console.log({ current });
  console.log({ certificateData });

  const getCertificate = () => {
    axios
      .get(`/api/v1/certificate/${id}`, config)
      .then((res) => {
        setCertificateData(res.data?.certificate);
        console.log({ certificateData });
        console.log({ res });
      })
      .catch((error) => console.log(error));
  };

  console.log({ answers });

  useEffect(() => {
    getCertificate();
    getQuestions();
    setLoading(true);
    axios
      .get(`/api/v1/series/getseries/${id}`, config)
      .then((res) => {
        setSingleSeries(res.data?.oneSeries);
        setPercentageComp(res.data?.percentage);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [config, id, videoUrl, percentageComp]);

  const checkSeriesStatus = useCallback(() => {
    axios
      .get(`/api/v1/series/checkseriescompleted/${id}`, config)
      .then((res) => {
        setSeriesIsCompleted(res.data?.finished);
      })
      .catch((err) => console.log(err));
  }, [config, id]);

  useEffect(() => {
    checkSeriesStatus();
  }, [checkSeriesStatus]);

  ////// GETTING THE CONTEXT STATE DATA AND CONTEXT DATA SETTING FUNCTION
  const { singleSeriesDetails } = useSeriesDetailsContext();
  const { setSingleSeriesDetails } = useSeriesDetailsContext();

  ///// SETTING THE SINGLE SERIES DATA TO CONTEXT STATE
  setTimeout(() => {
    setSingleSeriesDetails(singleSeries);
  }, 0);

  ////// TO GET CLICKED VIDEO'S URL
  const getVideoUrl = (url, id, index, videoTitle) => {
    setVideoUrl(url);
    setVideoId(id);
    setVideoIndex(index);
    setVideoTitle(videoTitle);
  };

  const toggleSubmitPrompt = () => {
    setShowSubmitPrompt(!showSubmitPropmt);
  };

  const toggleShowVideoPlayer = () => {
    setShowVideoPlayer(!showVideoPlayer);
  };
  console.log({ singleSeriesDetails });
  ////// TO START A SERIES WITH THE FIRST VIDEO
  const startSeries = () => {
    setTimeout(() => {
      axios
        .put(
          `/api/v1/content/markOngoing/${singleSeriesDetails.Contents[0]?.id}`,
          {},
          config
        )
        .then(() =>
          // mixpanel.track("START-SERIES", {
          //   seriesTitle: `${singleSeriesDetails.title}`,
          // })
          gAStartSeries(singleSeriesDetails.title)
        )
        .catch((error) => console.log(error));
    }, 2000);

    setVideoUrl(singleSeriesDetails.Contents[0]?.url);
    toggleShowVideoPlayer();
  };

  const takeQuiz = () => {
    setShowStartQuiz(!showStartQuiz);
  };

  const toggleShowQuestion = () => {
    setShowQuestion(!showQuestion);
  };

  const startQuiz = () => {
    toggleShowQuestion();
    takeQuiz();
    // mixpanel.track("QUIZ-STARTED", {
    //   seriesTitle: `${singleSeriesDetails.title}`,
    // });
    gAQuizStarted(singleSeriesDetails.title);
  };

  const markQuestions = () => {
    axios
      .post(
        `/api/v1/question/markQuestions/${id}`,
        {
          answer: answers,
        },
        config
      )
      .then((res) => {
        setResult(res.data);
        console.log(res.data);
        setCertificateData(res.data);
        setShowResult(!showResult);
      })
      .catch((err) => {
        setShowResult(!showResult);
        console.log(err);
      });

    // mixpanel.track("QUIZ-COMPLETED", {
    //   seriesTitle: `${singleSeriesDetails.title}`,
    // });

    gAQuizCompleted(singleSeriesDetails.title);
  };

  const showPassOrFail = () => {
    setShowResult(!showResult);
    setShowQuestion(!showQuestion);
    console.log("clicked");
  };

  const questionsLength = quizQuestion.questions?.length - 1;

  const selectAnswer = (questionId, value) => {
    const newAnswer = {
      questionId: currentQuestionId ? currentQuestionId : questionId,
      answer: value,
    };
    setSelectedAnswer(newAnswer);
  };

  const recordAnswer = (questionId, currentQuestionNum) => {
    const existingAnswer = answers.map((answer) =>
      answer.questionId === questionId ? true : false
    );

    if (existingAnswer[currentQuestionNum] && selectedAnswer !== "") {
      setAnswers((prevState) =>
        prevState.map((answer) =>
          answer.questionId === questionId
            ? { ...answer, answer: selectedAnswer.answer }
            : answer
        )
      );
      setSelectedAnswer("");
    } else {
      if (selectedAnswer !== "") {
        const newAnswer = {
          questionId: currentQuestionId ? currentQuestionId : questionId,
          answer: selectedAnswer.answer,
        };

        setAnswers([...answers, newAnswer]);
      }
      setSelectedAnswer("");
    }
  };

  const resultToBeShown = result?.certificate ? (
    <Passed
      showPassOrFail={showPassOrFail}
      result={result}
      seriesTitle={singleSeriesDetails.title}
    />
  ) : (
    <FailedComponent
      showPassOrFail={showPassOrFail}
      setShowQuestion={setShowQuestion}
      setCurrentQuestionNum={setCurrentQuestionNum}
      setShowResult={setShowResult}
      setAnswers={setAnswers}
      setSeriesIsCompleted={setSeriesIsCompleted}
    />
  );

  console.log({ currentQuestionNum });

  const completeQuizSubmission = () => {
    markQuestions();
    toggleSubmitPrompt();
  };

  return (
    <>
      <GAMetaDecorator
        title="Single - Series"
        description="Fezzant's single series page"
      />
      <div className="series__details--container">
        {loading ? <Loading type="cylon" color="rgb(101, 93, 138)" /> : null}
        <div className="series__details-content--container">
          {showSubmitPropmt ? (
            <OverlayPrompt
              toggleSubmitPrompt={toggleSubmitPrompt}
              markQuestions={markQuestions}
              promptText="Are you sure you want to submit?"
              promptButtonTextOne="Yeah, pretty sure"
              promptButtonTexttwo="Nah, not sure"
              icon={<ExpiredIcon />}
              functionOne={completeQuizSubmission}
              functionTwo={toggleSubmitPrompt}
            />
          ) : null}

          {showVideoPlayer ? (
            <span>
              <VideoPlayer
                key={videoUrl}
                videoUrl={videoUrl}
                videoId={videoId}
                seriesId={id}
                toggleShowVideoPlayer={toggleShowVideoPlayer}
                showVideoPlayer={showVideoPlayer}
                setVideoUrl={setVideoUrl}
                setVideoId={setVideoId}
                thumbnail={singleSeriesDetails.smallThumbnail}
                seriesTitle={singleSeriesDetails?.title}
                videoIndex={videoIndex}
                videoTitle={videoTitle}
                showStartQuiz={showStartQuiz}
                takeQuiz={takeQuiz}
                checkSeriesStatus={checkSeriesStatus}
                seriesIsCompleted={seriesIsCompleted}
              />
            </span>
          ) : (
            <SeriesInfo
              singleSeriesDetails={singleSeriesDetails}
              percentageComp={percentageComp}
              takeQuiz={takeQuiz}
              singleSeries={takeQuiz}
              startSeries={startSeries}
              certificateData={certificateData}
              seriesIsCompleted={seriesIsCompleted}
              id={id}
            />
          )}

          {showStartQuiz ? (
            <StartQuiz
              takeQuiz={takeQuiz}
              startQuiz={startQuiz}
              thumbnail={singleSeriesDetails.smallThumbnail}
            />
          ) : null}

          {showResult ? resultToBeShown : null}
          {showQuestion &&
            quizQuestion.questions.map((question, index) => {
              if (index === currentQuestionNum) {
                console.log({ index });
                return (
                  <QuestionComponent
                    toggleShowVideoPlayer={toggleShowVideoPlayer}
                    options={question.options}
                    question={question.question}
                    currentQuestionNum={currentQuestionNum}
                    setCurrentQuestionNum={setCurrentQuestionNum}
                    questionsLength={questionsLength}
                    image={question.image}
                    seriesId={id}
                    selectedAnswer={selectedAnswer}
                    selectAnswer={selectAnswer}
                    answers={answers}
                    questionId={question.id}
                    recordAnswer={recordAnswer}
                    markQuestions={markQuestions}
                    setSelectedAnswer={setSelectedAnswer}
                    toggleShowQuestion={toggleShowQuestion}
                    showPassOrFail={showPassOrFail}
                    questions={quizQuestion?.questions}
                    setCurrentQuestionId={setCurrentQuestionId}
                    toggleSubmitPrompt={toggleSubmitPrompt}
                  />
                );
              } else {
                return;
              }
            })}

          {showResult && resultToBeShown}

          <div className="series__videos--list">
            {singleSeriesDetails.Contents?.map((content, index) => (
              <Video
                key={content.id}
                index={index}
                id={content.id}
                title={content.title}
                type={content.type}
                number={index + 1}
                url={content.url}
                getVideoUrl={getVideoUrl}
                videoUrl={videoUrl}
                thumbnail={content.thumbnail}
                showVideoPlayer={showVideoPlayer}
                toggleShowVideoPlayer={toggleShowVideoPlayer}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default SeriesDetails;
