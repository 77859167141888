import { useState, useRef, useMemo, useEffect } from "react";
import { ReactComponent as FezzantLogo } from "../../assets/icons/fezzant-beta.svg";
import { ReactComponent as MailLogo } from "../../assets/icons/mail-icon.svg";
import axios from "axios";
import { ReactComponent as EyeLogo } from "../../assets/icons/eye-icon.svg";
import { ReactComponent as EyeOffLogo } from "../../assets/icons/eye-off.svg";
import { ReactComponent as LockLogo } from "../../assets/icons/lock-icon.svg";
import { ReactComponent as NameIcon } from "../../assets/icons/name-icon.svg";
import Loading from "../Loading/Loading.component";
import { toast } from "react-toastify";
import { ReactComponent as XIcon } from "../../assets/icons/x-svg.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";

import "./completeAccSetUp.styles.css";

const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const COMPLETESETUPURL = "/api/v1/business/completeuser";

const CompleteAccSetUp = () => {
  const [errorMessage, setErrorMessage] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [businessId, setBusinessId] = useState("");

  const [type, setType] = useState("password");

  const [pwd, setPwd] = useState("");
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);

  const [matchPwd, setMatchPwd] = useState("");
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);

  const emailRef = useRef();

  const search = useLocation().search;
  const userToken = new URLSearchParams(search).get("token");

  const togglePasswordType = () => {
    setShowPassword(!showPassword);
    setType(showPassword ? "text" : "password");
  };

  const navigate = useNavigate();

  const token = localStorage.getItem("token");

  const config = useMemo(() => {
    return {
      headers: { Authorization: `Bearer ${token}` },
    };
  }, [token]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    //if button enabled with JS hack

    try {
      setLoading(true);
      const response = await axios.post(
        COMPLETESETUPURL,
        {
          password: pwd,
          businessId: businessId,
          email: email,
        },
        config
      );
      setSuccess(true);
      toast("Set Up Complete!");
      setFullName("");
      setEmail("");
      setPwd("");
      setMatchPwd("");

      setLoading(false);
      setTimeout(() => {
        navigate("/");
      }, 2500);
    } catch (error) {
      if (!error?.response) {
        setErrMsg("No Server Response");
      } else if (error.response?.status === 400) {
        setErrMsg("User Already Exists");
      } else {
        setErrMsg("Registration Failed");
      }

      setLoading(false);
    }
  };

  useEffect(() => {
    const result = PWD_REGEX.test(pwd);
    setValidPwd(result);
    const match = pwd === matchPwd;
    setValidMatch(match);
  }, [pwd, matchPwd]);

  useEffect(() => {
    setErrMsg("");
  }, [pwd, matchPwd]);

  useEffect(() => {
    setLoading(true);
    axios
      .post("/api/v1/business/confirmtoken", { token: userToken })
      .then((res) => {
        setEmail(res.data.userToken.email);
        setFullName(res.data.userToken.name);
        setBusinessId(res.data.userToken.businessId);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [userToken]);

  return (
    <>
      {loading ? <Loading type="cylon" color="rgb(101, 93, 138)" /> : null}
      {errMsg ? <h2>{errMsg}</h2> : null}
      <div className="completeAccSetUp__container">
        <div className="completeAccSetUp__content--container">
          <div className="completeAccSetUp__form--container">
            <span className="addUser__logo-span">
              <FezzantLogo />
            </span>

            <div className="add-user__form-and-text__container">
              <span className="addUser__heading-and-close--CTA">
                <span className="completeAccSetUp__form--texts">
                  <h2 className="completeAccSetUp__formheading">
                    Complete Account Set-up
                  </h2>

                  <p className="completeAccSetUp__form-sub--text">
                    Set your password to complete your account set-up.
                  </p>
                </span>
                <Link to="/company-panel/users">
                  <XIcon style={{ fill: "#F95959" }} />
                </Link>
              </span>
              <form
                action="
                "
                className="completeAccSetUp__form"
                onSubmit={handleSubmit}
              >
                <label
                  htmlFor="email"
                  className="completeAccSetUp__email--label"
                >
                  Email Address
                </label>
                <div className="completeAccSetUp__email-and-icon">
                  <input
                    type="email"
                    id="email"
                    ref={emailRef}
                    autoComplete="off"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    disabled
                    aria-describedby="uidnote"
                    className={
                      errorMessage
                        ? "email--input"
                        : "createCompLogin__email--input"
                    }
                    style={{ background: "#F7F7F7" }}
                  />

                  <MailLogo className="mail__icon" />
                </div>
                <label
                  htmlFor="firstName"
                  className="completeAccSetUp__password--label"
                >
                  Full Name
                </label>
                <div className="completeAccSetUp__password-and-icons">
                  <input
                    type="text"
                    className={
                      errorMessage
                        ? "email--input"
                        : "createCompLogin__email--input"
                    }
                    id="fullName"
                    autoComplete="off"
                    onChange={(e) => fullName(e.target.value)}
                    value={fullName}
                    disabled
                    aria-describedby="uidnote"
                    style={{ background: "#F7F7F7" }}
                  />

                  <NameIcon className="mail__icon" />
                </div>

                <label
                  htmlFor="password"
                  className="createCompLogin__password--label"
                >
                  Password
                </label>
                <div className="createCompLogin__password-and-icons">
                  <input
                    type={type}
                    className={
                      errorMessage || (pwdFocus && !validPwd)
                        ? "email--input"
                        : "createCompLogin__email--input"
                    }
                    id="password"
                    value={pwd}
                    onChange={(e) => setPwd(e.target.value)}
                    autoComplete="false"
                    required
                    aria-invalid={validPwd ? "false" : "true"}
                    aria-describedby="pwdnote"
                    onFocus={() => setPwdFocus(true)}
                    onBlur={() => setPwdFocus(false)}
                    style={{ background: "#F7F7F7" }}
                  />
                  <p
                    id="pwdnote"
                    className={
                      pwdFocus && !validPwd ? "instruction" : "offScreen"
                    }
                    style={{ background: "#F7F7F7" }}
                  >
                    8 to 24 characters.
                    <br />
                    Must include uppercase and lowercase letters, a number and a
                    special character.
                    <br />
                    Allowed special characters:{" "}
                    <span aria-label="exclamation mark">!</span>{" "}
                    <span aria-label="at symbol">@</span>{" "}
                    <span aria-label="hashtag">#</span>{" "}
                    <span aria-label="dollar sign">$</span>{" "}
                    <span aria-label="percent">%</span>
                  </p>

                  {type === "password" ? (
                    <EyeLogo
                      className="eye__icon"
                      onClick={togglePasswordType}
                    />
                  ) : (
                    <EyeOffLogo
                      className="eye__icon"
                      onClick={togglePasswordType}
                    />
                  )}
                  <LockLogo className="lock__icon" />
                </div>
                <label
                  htmlFor="confirm_pwd"
                  className="createCompLogin__password--label"
                >
                  Confirm Password
                </label>
                <div className="createCompLogin__password-and-icons">
                  <input
                    type={type}
                    id="confirm_pwd"
                    value={matchPwd}
                    onChange={(e) => setMatchPwd(e.target.value)}
                    autoComplete="false"
                    required
                    aria-invalid={validMatch ? "false" : "true"}
                    aria-describedby="confirmNote"
                    onFocus={() => setMatchFocus(true)}
                    onBlur={() => setMatchFocus(false)}
                    className={
                      errorMessage || (matchFocus && !validMatch)
                        ? "email--input"
                        : "createCompLogin__email--input"
                    }
                    style={{ background: "#F7F7F7" }}
                  />
                  <p
                    id="confirmnote"
                    className={
                      matchFocus && !validMatch ? "instruction" : "offScreen"
                    }
                  >
                    Must match the first password input field.
                  </p>

                  {type === "password" ? (
                    <EyeLogo
                      className="eye__icon"
                      onClick={togglePasswordType}
                    />
                  ) : (
                    <EyeOffLogo
                      className="eye__icon"
                      onClick={togglePasswordType}
                    />
                  )}
                  <LockLogo className="lock__icon" />
                </div>

                <button className="completeAccSetUp__submit--CTA">
                  Accept Invite
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompleteAccSetUp;
