import { useEffect, useMemo, useState } from "react";
import { ReactComponent as FilmRoll } from "../../assets/icons/video-iconTwo.svg";
import { ReactComponent as StarIcon } from "../../assets/icons/coin-icon.svg";
import { ReactComponent as ClockIcon } from "../../assets/icons/clock-icon.svg";
import Video from "../VideoComponent/Video.component";
import { useParams } from "react-router-dom";
import VideoPlayer from "../VideoPlayer/VideoPlayer.component";
import axios from "axios";
import { useSeriesDetailsContext } from "../../contexts/SeriesContext";
import Loading from "../Loading/Loading.component";
import ProgressBar from "../ProgressBar/ProgressBar.component";
// import "./seriesDetails.styles.css";
import StartQuiz from "../StartQuiz/StartQuiz";
import QuestionComponent from "../QuestionComponent/QuestionComponent";

const QuizOption = ({
  selectAnswer,
  setSelected,
  selected,
  option,
  currentSelected,
  setCurrentSelected,
  questionId,
  index,
  currentQuestionNum,
  answers,
}) => {
  return (
    <label className="container">
      <p className="answer__option">{option}</p>
      <input
        type="radio"
        name="radio"
        value={option}
        onChange={(e) => {
          selectAnswer(questionId, e.target.value);
          setCurrentSelected(e.target.value);
        }}
        checked={
          answers[currentQuestionNum]?.answer === option ||
          currentSelected === option
        }
      />
      <span className="checkmark"></span>
    </label>
  );
};

export default QuizOption;
