import { useState, useEffect } from "react";
import "./progressBar.styles.css";
const ProgressBar = ({ done, width }) => {
  const [style, setStyle] = useState({});

  useEffect(() => {
    setTimeout(() => {
      const newStyle = {
        opacity: 1,
        width: `${done}%`,
      };

      setStyle(newStyle);
    }, 200);
  });

  return (
    <div className="progress" style={{ width: `${width}` }}>
      <div className="progress-done" style={style}>
        {/* {done}% */}
      </div>
    </div>
  );
};

export default ProgressBar;
