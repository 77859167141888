// import { ReactComponent as UpArrowIcon } from "../../assets/icons/up-arrow.svg";
import { ReactComponent as GoldMedalIcon } from "../../assets/icons/gold-medal.svg";
import { ReactComponent as BronzeMedalIcon } from "../../assets/icons/third-medal.svg";
import { ReactComponent as SilverMedalIcon } from "../../assets/icons/second-medal.svg";
import "./leaderboardTable.styles.css";

const LeaderboardTable = ({ name, points, position }) => {
  let medal;
  if (position === 0) {
    medal = <GoldMedalIcon />;
  } else if (position === 1) {
    medal = <SilverMedalIcon />;
  } else if (position === 2) {
    medal = <BronzeMedalIcon />;
  } else {
    medal = position + 1;
  }

  // For getting username acronym

  const str = `${name?.toUpperCase()}`;
  const matches = str.match(/\b(\w)/g);
  const acronym = matches.join(" ");
  return (
    <>
      <tbody>
        <tr className="leaderboard__row">
          <td className="medal__table--data">{medal}</td>
          <td className="">
            <span className="user__image-and-name">
              <span className="user__image">{acronym}</span>
              <span className="name-and-company">
                <p className="user__name">{name}</p>
                <p className="company">COMPANY </p>
              </span>
            </span>
          </td>
          <td>
            <span className="points-and-icon">
              {/* <UpArrowIcon className="points__icon" /> */}
              <p className="points__figure">{points} Points</p>
            </span>
          </td>
        </tr>
      </tbody>
    </>
  );
};

export default LeaderboardTable;
