import { useState, useEffect, useMemo } from "react";
import Series from "../Series/Series.component";
import MobileSeries from "../MobileSeries/MobileSeries.component";
import { useAuthUserContext } from "../../contexts/AuthContext";
import { useAuthContext } from "../../contexts/AuthContext";
import { useAllSeriesContext } from "../../contexts/AllSeriesContext";
import axios from "axios";
import Loading from "../Loading/Loading.component";
import OverlayPrompt from "../OverlayPrompt/OverlayPrompt.component";
import GAMetaDecorator from "../GAMetaDecorator/GAMetaDecorator";

import "./seriesList.styles.css";

const SeriesList = () => {
  const [series, setSeries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { authUserData } = useAuthUserContext();
  const { authDetails } = useAuthContext();
  const { allSeries, setAllSeries } = useAllSeriesContext();

  console.log({ authDetails });

  const token = localStorage.getItem("token");

  const config = useMemo(() => {
    return {
      headers: { Authorization: `Bearer ${token}` },
    };
  }, [token]);

  console.log({ authUserData });

  useEffect(() => {
    setLoading(true);
    axios
      .get("/api/v1/series", config)
      .then((res) => {
        console.log(res);
        setSeries(res.data.series);
        setAllSeries(res.data.series);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response?.status === 400) {
          setErrorMessage("Incorrect Username or Password.");
        }

        setLoading(false);
      });
  }, [config]);

  console.log(allSeries);

  return (
    <>
      <GAMetaDecorator
        title="Fezzant-Series-Page"
        description="Fezzant's Series page"
      />
      <div className="series__list--container">
        {/* {authUserData.subInfo?.isActive ? null : (
        <OverlayPrompt
          promptText="Renew your plan to start a series or continue where you left off."
          promptButtonText="Renew Subcription"
        />
      )} */}
        {loading ? <Loading type="cylon" color="rgb(101, 93, 138)" /> : null}
        <span className="seriesList__heading--span">
          <h2 className="seriesList__heading">Series Collection</h2>
        </span>
        <div className="series__list--content">
          {series.map((series, index) => {
            return (
              <Series
                key={series.id}
                title={series.title}
                image={series.thumbnail}
                seriesId={series.id}
                points={series.totalPoints}
                content={series.Contents}
                duration={series.duration}
                percentageCompleted={series.percentage}
                index={index}
                authDetails={authDetails}
              />
            );
          })}
        </div>
        <div className="mobile-series">
          {series.map((series, index) => {
            return (
              <MobileSeries
                key={series.id}
                title={series.title}
                image={series.thumbnail}
                seriesId={series.id}
                points={series.totalPoints}
                content={series.Contents}
                duration={series.duration}
                percentageCompleted={series.percentage}
                index={index}
                authDetails={authDetails}
                authUserData={authUserData}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default SeriesList;
