import { useRef } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import SeriesList from "./components/SeriesList/SeriesList.component";
import SeriesDetails from "./components/SeriesDetaills/SeriesDetails.component";
import Panel from "./components/Panel/Panel.component";
import Progress from "./components/Progress/Progress.component";
import Login from "./components/Login/Login.component";
import Leaderboard from "./components/Leaderboard/Leaderboard.component";
import Profile from "./components/Profile/Profile.component";
import Register from "./components/Register/Register";
import Users from "./components/Users/Users.component";
import CompanyPanel from "./components/CompanyPanel/CompanyPanel.component";
import ProtectedRoutes from "./components/protectedRoutes";
import { AnimatePresence } from "framer-motion";
// import CompanyLogin from "./components/CompanyLogin/CompanyLogin";
import CreateCompAcc from "./components/CreateCompanyAcc/CreateCompAcc.component";
import AddUserForm from "./components/AddUserForm/AddUserForm";
import CompleteAccSetUp from "./components/CompleteAccSetUp/CompleteAccSetUp.component";
import UserPage from "./components/UserPage/UserPage.component";
import { ToastContainer } from "react-toastify";
import mixpanel from "mixpanel-browser";
import { useAuthContext } from "./contexts/AuthContext";
import { QueryClient, QueryClientProvider } from "react-query";
import AdminDashboard from "./components/AdminDashboard/AdminDashboard";
import DownloadCert from "./components/DownloadCert/DownloadCert.component";
import SharedCert from "./components/SharedCert/SharedCert.component";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import MetaDecorator from "./components/MetaDecorator/MetaDecorator";
import metaThumbnail from "./assets/images/fezzant-07.png";
import ReactGA from "react-ga4";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

// mixpanel.init("f3574152559b2e61aa7f78dc5a2dee86", {
//   debug: true,
// });

function App() {
  const tawkMessengerRef = useRef();

  const btnClicked = (e) => {
    mixpanel.track("Clicked Tracked");
  };

  // try {
  //   setTimeout((_) => {
  //     const ga4react = new ReactGa("G-KTKM871PBN");
  //     ga4react.initialize().catch((err) => console.error(err));
  //   }, 4000);
  // } catch (err) {
  //   console.error(err);
  // }

  // const gAnalyticsId = process.env.REACT_APP_GOOGLE_ANALYTICS_KEY;

  const gAnalyticsDevId = process.env.REACT_APP_GOOGLE_ANALYTICS_KEY;

  ReactGA.initialize(gAnalyticsDevId);

  console.log(gAnalyticsDevId);

  const location = useLocation();
  const queryClient = new QueryClient();

  const { authDetails } = useAuthContext();

  const isLoggedIn = localStorage.getItem("token");

  const customStyle = {
    zIndex: "50 !important",
    visibility: {
      desktop: {
        xOffset: "1",
        yOffset: "70",
        position: "br",
      },

      mobile: {
        xOffset: 1,
        yOffset: 70,
        position: "br",
      },
    },
  };

  return (
    <div className="App">
      <MetaDecorator
        title="Welcome to Fezzant!"
        description="Engaging simplified and relatable scenario-based training to keep your employees prepared for the latest cyber threats"
        imageDescription="The Fezzant's Logo"
        imageUrl={metaThumbnail}
      />
      <QueryClientProvider client={queryClient}>
        <ToastContainer />
        <AnimatePresence exitBeforeEnter>
          <Routes location={location} key={location.key}>
            {/* <Route element={<ProtectedRoutes />}> */}
            <Route path="/panel" element={<Panel />}>
              <Route path="/panel/series" element={<SeriesList />} />
              <Route
                path="/panel/company-series-details/:id"
                element={<SeriesDetails />}
              />
              <Route path="/panel/progress" element={<Progress />} />
              <Route
                path="/panel/leaderboard"
                element={<Leaderboard title="Leaderboard" />}
              />
              <Route path="/panel/profile" element={<Profile />} />
            </Route>
            <Route path="/company-panel" element={<CompanyPanel />}>
              <Route path="/company-panel/series" element={<SeriesList />} />
              <Route
                path="/company-panel/series-details/:id"
                element={<SeriesDetails />}
              />
              <Route path="/company-panel/users" element={<Users />} />

              <Route path="/company-panel/progress" element={<Progress />} />
              <Route
                path="/company-panel/admin-dashboard"
                element={<AdminDashboard />}
              />
              <Route
                path="/company-panel/leaderboard"
                element={<Leaderboard title="Leaderboard" />}
              />
              <Route
                path="/company-panel/profile"
                element={<Profile title="Profile" />}
              />
              <Route path="/company-panel/user/:id" element={<UserPage />} />
            </Route>
            <Route path="/add-user" element={<AddUserForm />} />
            {/* </Route> */}
            <Route
              path="/"
              element={
                isLoggedIn ? <Navigate to="/company-panel/series" /> : <Login />
              }
            />
            <Route path="/company-panel/series" element={<SeriesList />} />
            <Route
              path="/company-panel/series-details/:id"
              element={<SeriesDetails />}
            />
            <Route path="/create-companyAcc" element={<CreateCompAcc />} />
            <Route path="/fufu007" element={<Register />} />
            <Route path="/complete-acc-setup" element={<CompleteAccSetUp />} />
            <Route path="/download-certificate" element={<DownloadCert />} />
            <Route
              path="/share-certificate/:certificateNo"
              element={<SharedCert />}
            />
          </Routes>
        </AnimatePresence>
      </QueryClientProvider>
      <TawkMessengerReact
        propertyId="62a0d8b97b967b11799389b1"
        widgetId="1g524t532"
        ref={tawkMessengerRef}
        customStyle={customStyle}
        // customStyle={{ zIndex: "1 !important" }}
      />
      {/* </Helmet> */}
    </div>
  );
}

export default App;
