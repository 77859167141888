import React, { useState } from "react";
import { dropDownOptions } from "../../dropDownData";
import { v4 as uuidv4 } from "uuid";
import { useAllSeriesContext } from "../../contexts/AllSeriesContext";
import { ReactComponent as DownArrow } from "../../assets/icons/down-arrow.svg";
import "./searchFilter.styles.css";

const SearchFilter = ({
  label,
  zIndex,
  setShowDropDown,
  showDropDown,
  width,
  addSelectedTraining,
  addSelectedTrainingId,
  allSeries,
  departments,
  setDepartmentId,
}) => {
  const [optionSearch, setOptionSearch] = useState("");
  const [openDropdown, setOpenDropDown] = useState(false);

  console.log({ departments });

  const filtered =
    allSeries &&
    allSeries.filter((series) =>
      series.title.toLowerCase().includes(optionSearch.toLowerCase())
    );
  const filteredDepts =
    departments &&
    departments.filter((department) =>
      department.name.toLowerCase().includes(optionSearch.toLowerCase())
    );

  let mappedOptions = filtered?.length ? filtered : "";
  let mappedDeptOptions = filteredDepts?.length ? filteredDepts : "";
  return (
    <div
      className="search__filter--form"
      style={{ width: width ? `${width}%` : "" }}
    >
      <label className="location__input--label">{label}</label>
      <div
        style={{
          position: "relative",
        }}
        className="login__password-and-icons"
      >
        <input
          className="assign__training--input"
          onChange={(e) => setOptionSearch(e.target.value)}
          onClick={() => {
            setShowDropDown(!showDropDown);
          }}
          value={`${optionSearch}`}
          style={{ width: width ? `${width}%` : "" }}
        />

        <DownArrow
          className={
            showDropDown
              ? "downarrow__in__detailsBar--flipped "
              : "downarrow__in__detailsBar "
          }
          style={{
            zIndex: "10000",
            top: "2.7rem",
            left: "90%",
            position: "absolute",
            bottom: 0,
            right: 0,
          }}
        />
      </div>
      {showDropDown ? (
        <div
          className="dropDown__container-main"
          style={{ zIndex: `${zIndex}` }}
        >
          {mappedOptions ? (
            <div className="location__options">
              {mappedOptions &&
                mappedOptions.map((series) => {
                  return (
                    <p
                      key={uuidv4()}
                      className="filter__option"
                      onClick={() => {
                        setOptionSearch(series.title);
                        addSelectedTraining(series);
                        addSelectedTrainingId(series.id);
                        setOptionSearch("");
                      }}
                    >
                      {series.title}
                    </p>
                  );
                })}
            </div>
          ) : (
            <div className="location__options">
              {mappedDeptOptions &&
                mappedDeptOptions.map((department) => {
                  return (
                    <p
                      key={uuidv4()}
                      className="filter__option"
                      onClick={() => {
                        setOptionSearch(department.name);
                        setDepartmentId(department.id);
                        setShowDropDown(!showDropDown);
                        // setOptionSearch("");
                      }}
                    >
                      {department.name}
                    </p>
                  );
                })}
            </div>
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default SearchFilter;
