import { useState, useRef, useMemo, useEffect } from "react";
import { ReactComponent as FezzantLogo } from "../../assets/icons/fezzant-beta.svg";
import { ReactComponent as MailLogo } from "../../assets/icons/mail-icon.svg";
import { ReactComponent as NameIcon } from "../../assets/icons/name-icon.svg";
import { ReactComponent as BackIcon } from "../../assets/icons/left-icon.svg";
import axios from "axios";
import { ReactComponent as XIcon } from "../../assets/icons/x-svg.svg";
import { toast } from "react-toastify";

import { Link } from "react-router-dom";
import Loading from "../Loading/Loading.component";
import "./addUser.styles.css";
import AlertComponent from "../AlertComponent/AlertComponent";

const ADDUSERURL = "/api/v1/business/adduser";

const AddUserForm = ({ setShowAddUserForm, departments }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [emailFocus, setEmailFocus] = useState(false);
  const [department, setDepartment] = useState("");
  const [departmentFocus, setDepartmentFocus] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [deptId, setDeptId] = useState("");
  const [success, setSuccess] = useState(false);
  const [status, setStatus] = useState("");

  const emailRef = useRef();

  const token = localStorage.getItem("token");

  const config = useMemo(() => {
    return {
      headers: { Authorization: `Bearer ${token}` },
    };
  }, [token]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const response = await axios.post(
        ADDUSERURL,
        {
          name: firstName + " " + lastName,
          email: email,
          departmentId: deptId,
        },
        config
      );
      setSuccess(true);
      // toast("invitation Sent!");
      setStatus(true);

      setFirstName("");
      setLastName("");
      setEmail("");
      setDeptId("");

      setLoading(false);
    } catch (error) {
      console.log(error.response.data.message);
      setErrMsg(error.response.data.message);
      // if (!error?.response) {
      //   setErrMsg("No Server Response");
      // } else if (error.response?.status === 400) {
      //   setErrMsg("User Already Exists");
      // } else {
      //   setErrMsg("Registration Failed");
      // }
      setStatus(false);
      setLoading(false);
    }
  };

  console.log({ errMsg });

  return (
    <div className="addUserForm__container">
      <div className="addUserForm__form--container">
        {/* <span className="addUser__logo-span">
          <FezzantLogo />
        </span> */}
        <div className="add-user__form-and-text__container width__class">
          <div className="alert__container--div">
            <AlertComponent
              status={status}
              setStatus={setStatus}
              successText="You have successfully added a user"
              failedText={errMsg}
              // successText={successMss}
              // failedText="You need to add users before you can assign training/series."
            />
          </div>
          <AlertComponent
            successText="You have successfully added a user"
            failedText="Error Adding User. Please retry."
          />
          <span className="add__dept--mobile-nav hide__mNav">
            <span
              style={{ display: "flex", alignItems: "center" }}
              onClick={() => setShowAddUserForm(false)}
            >
              <BackIcon style={{ marginRight: "1.2rem" }} /> <p>Back</p>
            </span>
            <p style={{ fontSize: "1.4rem", fontWeight: "400" }}>Add User</p>
          </span>
          <span className="addUser__heading-and-close--CTA">
            <span className="addUserForm__form--texts">
              {loading ? (
                <Loading type="cylon" color="rgb(101, 93, 138)" />
              ) : null}
              <h2 className="addUserForm__formheading">Add User</h2>

              {/* {errorMessage ? (
                <p
                  style={{
                    color: "#F95959",
                    marginBottom: "1rem",
                  }}
                >
                  {errorMessage}
                </p>
              ) : (
                ""
              )} */}
              <p className="addUserForm__form-sub--text">
                Add a new User (limit: 20)
              </p>
            </span>
            {/* <Link to="/company-panel/users"> */}
            <XIcon
              style={{ fill: "#F95959", cursor: "pointer" }}
              onClick={() => setShowAddUserForm(false)}
              className="hide__arrow"
            />
            {/* </Link> */}
          </span>
          {/* {errMsg ? <p style={{ color: "#F95959" }}>{errMsg}</p> : null} */}
          <form
            action="
                "
            className="addUserForm__form"
            onSubmit={handleSubmit}
          >
            <label htmlFor="Email" className="addUserForm__email--label">
              Email Address
            </label>
            <div className="addUserForm__email-and-icon">
              <input
                type="email"
                id="email"
                ref={emailRef}
                autoComplete="off"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                required
                aria-describedby="uidnote"
                onFocus={() => setEmailFocus(true)}
                onBlur={() => setEmailFocus(false)}
                className={
                  errorMessage
                    ? "email--input"
                    : "createCompLogin__email--input"
                }
                style={{
                  borderRadius: "1rem",
                  paddingLeft: "2rem",
                  width: "100%",
                }}
              />
              {errorMessage && (
                <p
                  style={{
                    color: "#F95959",
                    marginTop: "1rem",
                  }}
                >
                  Email is required
                </p>
              )}
              {/* <MailLogo className="mail__icon" /> */}
            </div>
            <label htmlFor="firstName" className="addUserForm__password--label">
              First Name
            </label>
            <div className="addUserForm__password-and-icons">
              <input
                type="text"
                className={
                  errorMessage
                    ? "email--input"
                    : "createCompLogin__email--input"
                }
                id="firstName"
                autoComplete="off"
                onChange={(e) => setFirstName(e.target.value)}
                value={firstName}
                required
                aria-describedby="uidnote"
                style={{
                  borderRadius: "1rem",
                  paddingLeft: "2rem",
                  width: "100%",
                }}
              />
              {errorMessage && (
                <p
                  style={{
                    color: "#F95959",
                    marginTop: "1rem",
                  }}
                >
                  First Name is required
                </p>
              )}
              {/* <NameIcon className="mail__icon" /> */}
            </div>
            <label htmlFor="lasttName" className="addUserForm__password--label">
              Last Name
            </label>
            <div className="addUserForm__password-and-icons">
              <input
                type="text"
                className={
                  errorMessage
                    ? "email--input"
                    : "createCompLogin__email--input"
                }
                id="lastName"
                autoComplete="off"
                onChange={(e) => setLastName(e.target.value)}
                value={lastName}
                required
                aria-describedby="uidnote"
                onFocus={() => setEmailFocus(true)}
                onBlur={() => setEmailFocus(false)}
                style={{
                  borderRadius: "1rem",
                  paddingLeft: "2rem",
                  width: "100%",
                }}
              />
              {errorMessage && (
                <p
                  style={{
                    color: "#F95959",
                    marginTop: "1rem",
                  }}
                >
                  Last Name is required
                </p>
              )}
              {/* <NameIcon className="mail__icon" /> */}
            </div>
            <label
              htmlFor="department"
              className="addUserForm__password--label"
            >
              Department
            </label>
            <div className="addUserForm__password-and-icons">
              {/* <input
                type="text"
                className={
                  errorMessage
                    ? "email--input"
                    : "createCompLogin__email--input"
                }
                id="department"
                autoComplete="off"
                onChange={(e) => setDepartment(e.target.value)}
                value={department}
                required
                aria-describedby="uidnote"
                onFocus={() => setDepartmentFocus(true)}
                onBlur={() => setDepartmentFocus(false)}
                style={{ borderRadius: "1rem", paddingLeft: "2rem" }}
              /> */}

              <select
                className={
                  errorMessage
                    ? "email--input"
                    : "createCompLogin__email--input"
                }
                style={{ padding: "0 2rem", width: "100%" }}
                onChange={(e) => setDeptId(e.target.value)}
              >
                <option value="">Select Department</option>
                {departments &&
                  departments.map((department) => {
                    return (
                      <option value={department.id}>{department.name}</option>
                    );
                  })}
              </select>
              {errorMessage && (
                <p
                  style={{
                    color: "#F95959",
                    marginTop: "1rem",
                  }}
                >
                  Last Name is required
                </p>
              )}
              {/* <NameIcon className="mail__icon" /> */}
            </div>

            <button
              className="addUserForm__submit--CTA"
              style={{ width: "100%" }}
            >
              Add User
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddUserForm;
