import { useState, useEffect, useMemo } from "react";
// import { ReactComponent as SettingsIcon } from "../../assets/icons/settings-icon.svg";
// import { ReactComponent as StarIcon } from "../../assets/icons/profile-star.svg";
// import { ReactComponent as GoldMedalIcon } from "../../assets/icons/gold-medal.svg";
// import { ReactComponent as CrownIcon } from "../../assets/icons/crown.svg";
// import { ReactComponent as ClosedRoadIcon } from "../../assets/icons/closed-road.svg";
// import { ReactComponent as VerifiedIcon } from "../../assets/icons/verified.svg";
import axios from "axios";
// import Leaderboard from "../Leaderboard/Leaderboard.component";
// import ActivityHeatMap from "../ActivityHeatMap/ActivityHeatMap.component";
import { useAuthUserContext } from "../../contexts/AuthContext";
// import OverlayPrompt from "../OverlayPrompt/OverlayPrompt.component";
import ProfileDataForm from "../ProfileDataForm/ProfileDataForm.component";
import GAMetaDecorator from "../GAMetaDecorator/GAMetaDecorator";
import "./profile.styles.css";

const Profile = () => {
  const [outlet, setOutlet] = useState("subscription");
  const [planDetails, setPlanDetails] = useState();
  const [loading, setLoading] = useState(false);

  const { authUserData } = useAuthUserContext();

  const userType = authUserData.user?.type;

  const pricePerUser = planDetails?.basePrice;

  const toggleOutlet = () => {
    if (outlet === "subscription") {
      setOutlet("progress");
    } else {
      setOutlet("subscription");
    }
  };

  const token = localStorage.getItem("token");

  const config = useMemo(() => {
    return {
      headers: { Authorization: `Bearer ${token}` },
    };
  }, [token]);

  // For getting username acronym

  const str = `${authUserData.user?.name.toUpperCase()}`;
  const matches = str.match(/\b(\w)/g);
  const acronym = matches.join("");

  return (
    <>
      <GAMetaDecorator
        title="Profile - Page"
        description="Fezzant's User Profile"
      />
      <div className="profile__container">
        {/* {authUserData.subInfo?.isActive ? null : (
        <OverlayPrompt
          promptText="Renew your plan to see and update your profile"
          promptButtonText="Renew Subcription"
        />
      )} */}
        <ProfileDataForm />

        {/* <div className="profile__content--container">
        <div className="profile__details-and-progress">
          <div className="profile__details--container">
            <span className="profile__settings--span">
              <SettingsIcon />
            </span>
            <span className="profile__image-and-points">
              <span className="profile__image-and-name">
                <span className="profile__image">{acronym}</span>
                <span className="profile__name-and-email">
                  <span className="profile__name-and-company">
                    <p className="profile__name">{authUserData.user?.name}</p>
                    <p className="profile__company">COMPANY NAME</p>
                  </span>
                  <p className="profile__email no__display">
                    {authUserData.user?.email}
                  </p>
                </span>
              </span>
              <span className="profile__points-and-level">
                <p className="profile__points">
                  {authUserData.user?.pointsWon} Points <StarIcon />
                </p>
                <p className="profile__level">
                  LVL VALUE <GoldMedalIcon />
                </p>
              </span>
            </span>
          </div>

          {userType !== "admin" ? (
            <div className="profile__progress--chart-container">
              <ActivityHeatMap />
            </div>
          ) : (
            <>
              <span className="progress-and-sub__switch">
                <span className={"annually__and__border"}>
                  <p
                    className={
                      outlet === "subscription" ? "monthly" : "unselect__plan"
                    }
                    onClick={toggleOutlet}
                  >
                    Subscription
                  </p>
                  <div
                    className={
                      outlet === "subscription"
                        ? "border__bottom"
                        : "hide__border"
                    }
                  ></div>
                </span>
                <span className={"annually__and__border"}>
                  <p
                    className={
                      outlet === "progress" ? "annually" : "unselect__plan"
                    }
                    onClick={toggleOutlet}
                  >
                    Progress
                  </p>
                  <div
                    className={
                      outlet === "progress" ? "border__bottom" : "hide__border"
                    }
                  ></div>
                </span>
              </span>
              <div className="subscription__and__progress--container">
                {outlet === "subscription" ? (
                  <div className="active__sub--container">
                    <h1 className="active__sub--heading">Fezzant for teams</h1>
                    <div className="active__plan--desc">
                      <div className="active__sub--benefits">
                        <span className="benefits__span">
                          <CrownIcon />
                          <p className="benefit">
                            {" "}
                            Access to all series and scenarios
                          </p>
                        </span>
                        <span className="benefit__span">
                          <VerifiedIcon />
                          <p className="benefit"> Monthly Employee report</p>
                        </span>
                        <span className="benefit__span">
                          <VerifiedIcon />

                          <p className="benefit"> 5 Users and above</p>
                        </span>
                        <span className="benefit__span">
                          <VerifiedIcon />

                          <p className="benefit">
                            {" "}
                            Access to all series and scenarios
                          </p>
                        </span>
                      </div>
                      <span className="active-sub--billing-info">
                        <h3 className="sub__price">
                          {" "}
                          <span>&#8358;</span> {pricePerUser}
                        </h3>
                        <p className="sub__per--user">Per User Per Month</p>
                        <div className="line"></div>
                      
                        <p className="next__sub--charge">
                          Your next bill is for $150,000
                          <br />
                          on 25th of May, 2022.
                        </p>
                      </span>
                    </div>
                    <span className="cancel__subCTA--span">
                      <button className="cancel__sub--CTA">
                        Cancel subscription
                      </button>
                    </span>
                  </div>
                ) : (
                  <div className="coming__soon">
                    <span className="sign__and__text">
                      <ClosedRoadIcon />
                      <p className="feature__coming">
                        Employee Progress Curve is still in the works
                        <br />
                        and will be available soon!
                      </p>
                    </span>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
        <div className="profileLeaderboard__container">
          <Leaderboard />
        </div>
      </div> */}
      </div>
    </>
  );
};

export default Profile;
