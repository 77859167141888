import React, { useState, useContext, createContext } from "react";

const AllSeriesContext = createContext();

export const useAllSeriesContext = () => {
  return useContext(AllSeriesContext);
};

export const AllSeriesProvider = ({ children }) => {
  const [allSeries, setAllSeries] = useState({});

  return (
    <AllSeriesContext.Provider value={{ allSeries, setAllSeries }}>
      {children}
    </AllSeriesContext.Provider>
  );
};
