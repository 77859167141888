import ReactLoading from "react-loading";
import "./loading.styles.css";

const Loading = ({ type, color }) => {
  return (
    <div className="loader__container">
      <ReactLoading type={type} color={color} height={"5%"} width={"5%"} />
    </div>
  );
};

export default Loading;
