import { useEffect, useState, useMemo } from "react";
import ProgressBar from "../ProgressBar/ProgressBar.component";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ReactComponent as FilmRoll } from "../../assets/icons/video-iconTwo.svg";
import { ReactComponent as ClockIcon } from "../../assets/icons/clock-icon.svg";
import mixpanel from "mixpanel-browser";

const SeriesInfo = ({
  takeQuiz,
  singleSeries,
  startSeries,
  singleSeriesDetails,
  certificateData,
  seriesIsCompleted,
  id,
}) => {
  const [percentageComp, setPercentageComp] = useState(0);

  const navigate = useNavigate();

  const token = localStorage.getItem("token");

  const config = useMemo(() => {
    return {
      headers: { Authorization: `Bearer ${token}` },
    };
  }, [token]);

  useEffect(() => {
    axios
      .get(`/api/v1/series/getseries/${id}`, config)
      .then((res) => {
        setPercentageComp(res.data?.percentage);
        console.log(percentageComp);
      })
      .catch((error) => {
        console.log(error);
      });
  });

  return (
    <div
      exit={{ opacity: 0 }}
      className="series__image-and-text__details"
      style={{
        // backgroundImage: `url(${singleSeriesDetails.thumbnail})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="blur__top" />
      <div
        className="series__img"
        style={{
          backgroundImage: `url(${singleSeriesDetails.smallThumbnail})`,
        }}
      ></div>
      <div className="series__title-and-description">
        <span className="series__descp-text--content">
          <span className="title-and-starPoints">
            <h1 className="videoSeries__title--text">
              {singleSeriesDetails.title}
            </h1>

            {/* <p className="star__points">
                {" "}
                {singleSeriesDetails.totalPoints} <StarIcon />
              </p> */}
          </span>
          <p className="series__desc--text">
            {singleSeriesDetails.description}
          </p>
        </span>
        <span className="progressBar-and-percent">
          <h4 className="completed__percentage">{percentageComp}%</h4>
          <ProgressBar done={percentageComp} width="75%" />
        </span>
        <span className="series__CTA-and-otherDetails">
          {certificateData && seriesIsCompleted ? (
            <button
              className="start__series--CTA"
              style={{ width: "20rem" }}
              onClick={() => {
                mixpanel.track("DOWNLOAD-CERTIFICATE", {
                  seriesTitle: `${singleSeriesDetails.title}`,
                });
                navigate("/download-certificate");
              }}
            >
              Download Certificate
            </button>
          ) : seriesIsCompleted ? (
            <button className="start__series--CTA" onClick={takeQuiz}>
              Take quiz
            </button>
          ) : percentageComp > 0 && percentageComp < 100 ? (
            <button
              className="start__series--CTA"
              style={{ width: "20rem" }}
              onClick={startSeries}
            >
              Resume Series
            </button>
          ) : (
            <button className="start__series--CTA" onClick={startSeries}>
              Start Series
            </button>
          )}
          <span className="series__details-and-icons">
            <span className="videos__details-and-icon">
              <FilmRoll />
              <p className="series__vid--num">
                {singleSeriesDetails.Contents?.length}{" "}
                {singleSeries.Contents?.length > 1 ? "Videos" : "Video"}
              </p>
            </span>
            <span className="duration__details-and-icon">
              <ClockIcon />
              <p className="series__scenatios--num">{singleSeries.duration}</p>
            </span>
          </span>
        </span>
      </div>
    </div>
  );
};

export default SeriesInfo;
