import { useEffect, useRef, useState, useMemo } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useNavigate } from "react-router-dom";
import { ReactComponent as CloseIcon } from "../../assets/icons/close-circleTwo.svg";
import { ReactComponent as FezzantLogo } from "../../assets/icons/fezzantLogo-main.svg";
import { ReactComponent as Barge } from "../../assets/icons/barge.svg";
import { ReactComponent as CertBarge } from "../../assets/icons/cert-barge.svg";
import { useCertificateContext } from "../../contexts/CertificateContext";
import { ReactComponent as ShareIcon } from "../../assets/icons/share-iconTwo.svg";
import { ReactComponent as DownloadIcon } from "../../assets/icons/download-icon.svg";
import { ReactComponent as CeoSignature } from "../../assets/icons/ceo-signature.svg";
import { ReactComponent as CeoSign } from "../../assets/icons/ceo-sign.svg";
import mixpanel from "mixpanel-browser";
import axios from "axios";
import { ReactComponent as LinkIcon } from "../../assets/icons/Link-icon.svg";
import { LinkedinShareButton, TwitterShareButton } from "react-share";
import { LinkedinIcon, TwitterIcon } from "react-share";
import ReactGA from "react-ga4";
import Pdf from "react-to-pdf";
import "./passed.styles.css";

mixpanel.init("f3574152559b2e61aa7f78dc5a2dee86", {
  debug: true,
});

const Passed = ({ showPassOrFail, seriesTitle }) => {
  const [showShareOptions, setShowShareOptions] = useState(false);

  const ref = useRef();
  const navigate = useNavigate();

  const token = localStorage.getItem("token");

  const config = useMemo(() => {
    return {
      headers: { Authorization: `Bearer ${token}` },
    };
  }, [token]);

  /////// Google analytics custom event functions
  const gADownloadCertificate = (seriesTitle) => {
    ReactGA.event({
      category: "DOWNLOAD-CERTIFICATE",
      action: "DOWNLOAD-CERTIFICATE",
      label: `${seriesTitle}`, // optional
      value: 99, // optional, must be a number
      nonInteraction: true, // optional, true/false
      transport: "xhr", // optional, beacon/xhr/image
    });
  };
  const gAShareCertificate = (seriesTitle) => {
    ReactGA.event({
      category: "SHARE-CERTIFICATE",
      action: "SHARE-CERTIFICATE",
      label: `${seriesTitle}`, // optional
      value: 99, // optional, must be a number
      nonInteraction: true, // optional, true/false
      transport: "xhr", // optional, beacon/xhr/image
    });
  };

  const { certificateData } = useCertificateContext();

  console.log(certificateData);

  const printMe = () => {
    var printContents = document.getElementById("printDiv").innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  };

  const nth = function (d) {
    if (d > 3 && d < 21) return "th";
    switch (d % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const issued = "2022-06-06T09:43:54.902Z";

  const d = new Date(issued.substr(0, 10));
  let month = months[d.getMonth()];
  const year = d.getFullYear();
  const day = d.getDate();
  const issuedDate = `${day}${nth(day)} of ${month}, ${year}`;
  console.log(issuedDate);

  // useEffect(() => {
  //   axios
  //     .get("/api/v1/certificate/view/:certificateNumber", config)
  //     .then((res) => console.log(res));
  // }, []);

  return (
    <>
      <div
        className="videoPlayer__container--two viewport__height"
        style={{ overflow: "visible !important" }}
      >
        {/* <DownloadCert /> */}
        <div className="passed__container">
          <span
            className="question__number-and-close__icon--passed"
            style={{ justifyContent: "flex-end", alignSelf: "end", height: "" }}
          >
            <CloseIcon
              onClick={() => {
                showPassOrFail();
              }}
              style={{ cursor: "pointer" }}
            />
          </span>
          <span className="passed__content">
            <h1 className="passed__heading">
              <span style={{ fontWeight: "700" }}>
                {/* {result.message} */}
                Congratulations!
              </span>{" "}
              You passed. You scored{" "}
              <span className="passedScore">{certificateData?.score}%</span> and
              you’ve been awarded a certificate! 🚀
            </h1>
            <div className="cert__container" ref={ref} id="printDiv">
              <div className="cert__content--container">
                <span className="logo-name-and-title">
                  <FezzantLogo style={{ marginBottom: "1rem" }} />
                  <p className="motto"> Securing your Human layer</p>
                  <p className="cert_of_completion">
                    Certificate of Completion
                  </p>
                  <h1 className="cert__course--title">
                    {/* [{result.certificate?.seriesName}] */}
                    {certificateData.certificate?.seriesName}
                  </h1>
                  <h1 className="cert__user--name">
                    {/* {result.certificate?.name} */}
                    {certificateData.certificate?.name}
                  </h1>
                </span>
                <div className="cert__desc-and-barge">
                  <span className="desc-and-sign">
                    <p className="cert__desc">
                      Has demonstrated a solid understanding and awareness of
                      {" " + certificateData.certificate?.seriesName}. <br /> In
                      recognition for this achievement, Fezzant is pleased{" "}
                      <br /> to award you this certificate.
                    </p>
                    <span className="sign__and__date">
                      <span className="sign__and__name">
                        {/* <p className="signature"> */}
                        <CeoSignature className="signature" />
                        <CeoSign className="mobile__sign" />
                        {/* </p> */}
                        <p className="cert__Ceo">Ali Yisa, CEO of Fezzant</p>
                      </span>
                      <span className="cert__date--span">
                        <span className="date__ofissuance--span">
                          <p className="cert__date">{issuedDate}</p>
                        </span>
                        <p className="cert__date--text">Certification Date</p>
                      </span>
                    </span>
                  </span>
                  <div className="barge">
                    <Barge />
                    <p className="cert__number">
                      {/* {result.certificate.certificateNumber} */}
                      {certificateData.certificate.certificateNumber}
                    </p>
                  </div>
                  <div className="mobile__barge">
                    <CertBarge />
                    <p className="cert__number">
                      {/* {result.certificate.certificateNumber} */}
                      {certificateData.certificate.certificateNumber}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <span className="share__and__continue--CTA">
              <span className="download__cert-and-share--CTAs">
                <span className="download__cert--CTA">
                  {/* <Pdf targetRef={ref} filename="my-fezzant-certificate.pdf">
                  {({ toPdf }) => ( */}
                  <DownloadIcon
                    style={{ cursor: "pointer" }}
                    // onClick={toPdf}
                    // onClick={() => {
                    //   setTimeout(printMe(), 100);
                    // }}
                    onClick={() => {
                      // mixpanel.track("DOWNLOAD-CERTIFICATE", {
                      //   seriesTitle: `${seriesTitle}`,
                      // });
                      gADownloadCertificate(seriesTitle);
                      navigate("/download-certificate");
                    }}
                    // onclick={() =>{setTimeout(window.print(),100)}}
                  />
                  {/* )}
          
                </Pdf> */}
                </span>
                <span className="share__cert--CTA">
                  {showShareOptions ? (
                    <span className="share__pop--up">
                      <p className="share__heading">Share</p>

                      <div className="social__share--icons">
                        <TwitterShareButton
                          url={`https://staging.fezzant.com/share-certificate/${certificateData.certificate.certificateNumber}`}
                          title="Checkout My Shiny Fezzant Certificate!"
                          // via="I just completed Fezzant's Email Security series! Checkout out my shiny new certificate. Cool, innit?"
                          hashtags={[
                            "fezzantcertified",
                            "emailsecurityexpertcomingthrough",
                          ]}
                          description="I just completed Fezzant's Email Security series! Checkout out my shiny new certificate. Cool, innit?"
                        >
                          <span className="logo_link--span">
                            <TwitterIcon borderRadius={3} size={22} />{" "}
                            <p className="share__icon--text">Twitter</p>
                          </span>
                        </TwitterShareButton>
                        <LinkedinShareButton
                          url={`https://staging.fezzant.com/share-certificate/${certificateData.certificate.certificateNumber}`}
                          title="Checkout My Shiny Fezzant Certificate!"
                          summary="I just completed Fezzant's Email Security series! Checkout out my shiny new certificate. Cool, innit?"
                          source="https://app.fezzant.com"
                        >
                          <span className="logo_link--span">
                            <LinkedinIcon borderRadius={3} size={22} />{" "}
                            <p className="share__icon--text">Linkedin</p>
                          </span>
                        </LinkedinShareButton>
                        <CopyToClipboard
                          text="https://staging.fezzant.com"
                          onCopy={() => alert("Copied")}
                        >
                          <span
                            className="logo_link--span"
                            style={{ cursor: "pointer" }}
                          >
                            <LinkIcon />{" "}
                            <p className="share__icon--text">Copy Link</p>
                          </span>
                        </CopyToClipboard>
                      </div>
                    </span>
                  ) : null}
                  <ShareIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setShowShareOptions(!showShareOptions);
                      // mixpanel.track("SHARE-CERTIFICATE");
                      gAShareCertificate(seriesTitle);
                    }}
                  />
                </span>
              </span>
              <button
                className="continue__CTA"
                onClick={() => navigate("/company-panel/series")}
              >
                Continue{" "}
              </button>
            </span>
          </span>
        </div>
      </div>
    </>
  );
};

export default Passed;
