import { useMemo, useEffect, useState } from "react";
import SideBar from "../SideBar/SideBar.component";
import CompanyDashboard from "./CompanyDashboard/CompanyDashboard.component";
import { ReactComponent as SeriesIcon } from "../../assets/icons/series-icon.svg";
import { ReactComponent as SeriesIconTwo } from "../../assets/icons/series-iconTwo.svg";
import { ReactComponent as ProgressIcon } from "../../assets/icons/progress-icon.svg";
import { ReactComponent as ProgressIconTwo } from "../../assets/icons/progress-iconTwo.svg";
import { ReactComponent as UserIcon } from "../../assets/icons/user-iconOne.svg";
import { ReactComponent as UserIconTwo } from "../../assets/icons/user-iconTwo.svg";
import { ReactComponent as StarIcon } from "../../assets/icons/star-icon2.svg";
import { ReactComponent as AdminDashIcon } from "../../assets/icons/adminDash-icon.svg";
import { ReactComponent as AdminDashIconTwo } from "../../assets/icons/adminDash-icon2.svg";
import { ReactComponent as UsersIcon } from "../../assets/icons/Users.svg";
import { ReactComponent as UsersIconTwo } from "../../assets/icons/users-iconTwo.svg";
import { useAuthUserContext } from "../../contexts/AuthContext";
import axios from "axios";
import { useAuthContext } from "../../contexts/AuthContext";
import PaymentOptions from "../PaymentOptions/PaymentOptions.component";
import "./companyPanel.styles.css";

const CompanyPanel = () => {
  const [logOutPrompt, setLogOutPropmt] = useState(false);
  const [showSubPrompt, setShowSubPrompt] = useState(false);

  const adminSideideBarLinks = [
    {
      linkId: Date.now(),
      urlName: "Series",
      urlLink: "/company-panel/series",
      urlIcon: <SeriesIcon />,
      urlIconTwo: <SeriesIconTwo />,
    },
    {
      linkId: Date.now(),
      urlName: "My Progress",
      urlLink: `/company-panel/progress`,
      urlIcon: <ProgressIcon />,
      urlIconTwo: <ProgressIconTwo />,
    },

    // {
    //   linkId: Date.now(),
    //   urlName: "Leaderboard",
    //   urlLink: `/company-panel/leaderboard`,
    //   urlIcon: <StarIcon />,
    // },
    {
      linkId: Date.now(),
      urlName: "Dashboard",
      urlLink: `/company-panel/admin-dashboard`,
      urlIcon: <AdminDashIcon />,
      urlIconTwo: <AdminDashIconTwo />,
    },
    {
      linkId: Date.now(),
      urlName: "Users",
      urlLink: `/company-panel/users`,
      urlIcon: <UsersIcon />,
      urlIconTwo: <UsersIconTwo />,
    },
    {
      linkId: Date.now(),
      urlName: "Profile",
      urlLink: `/company-panel/profile`,
      urlIcon: <UserIcon />,
      urlIconTwo: <UserIconTwo />,
    },
    // {
    //   linkId: Date.now(),
    //   urlName: "Users",
    //   urlLink: `/company-panel/users`,
    //   urlIcon: <UsersIcon />,
    // },
  ];
  const staffSideideBarLinks = [
    {
      linkId: Date.now(),
      urlName: "Series",
      urlLink: "/company-panel/series",
      urlIcon: <SeriesIcon />,
      urlIconTwo: <SeriesIconTwo />,
    },
    {
      linkId: Date.now(),
      urlName: "My Progress",
      urlLink: `/company-panel/progress`,
      urlIcon: <ProgressIcon />,
      urlIconTwo: <ProgressIconTwo />,
    },

    // {
    //   linkId: Date.now(),
    //   urlName: "Leaderboard",
    //   urlLink: `/company-panel/leaderboard`,
    //   urlIcon: <StarIcon />,
    // },
    // {
    //   linkId: Date.now(),
    //   urlName: "Risk Curve",
    //   urlLink: `/company-panel/riskCurve`,
    //   urlIcon: <AlertIcon />,
    // },
    {
      linkId: Date.now(),
      urlName: "Profile",
      urlLink: `/company-panel/profile`,
      urlIcon: <UserIcon />,
      urlIconTwo: <UserIconTwo />,
    },
    // {
    //   linkId: Date.now(),
    //   urlName: "Users",
    //   urlLink: `/company-panel/users`,
    //   urlIcon: <UsersIcon />,
    // },
  ];

  const token = localStorage.getItem("token");

  const { authUserData, setAuthUserData } = useAuthUserContext();

  const { authDetails, setAuthDetails } = useAuthContext();

  const config = useMemo(() => {
    return {
      headers: { Authorization: `Bearer ${token}` },
    };
  }, [token]);

  useEffect(() => {
    axios
      .get("/api/v1/user/getLoggedInUser", config)
      .then((res) => {
        if (!res.data.subInfo?.isActive && !res.data.subInfo?.freetrial) {
          setShowSubPrompt(true);
        }
        setAuthUserData(res.data);
        console.log(res.data.subInfo?.freetrial);
      })
      .catch((error) => console.log(error));
  }, [token, config, setAuthUserData]);

  // console.log(token);

  return (
    <div className="panel__container">
      {showSubPrompt && <PaymentOptions />}

      <SideBar
        adminSideideBarLinks={adminSideideBarLinks}
        staffSideideBarLinks={staffSideideBarLinks}
        logOutPrompt={logOutPrompt}
        setLogOutPropmt={setLogOutPropmt}
      />
      <CompanyDashboard
        adminSideideBarLinks={adminSideideBarLinks}
        staffSideideBarLinks={staffSideideBarLinks}
        logOutPrompt={logOutPrompt}
        setLogOutPropmt={setLogOutPropmt}
      />
    </div>
  );
};

export default CompanyPanel;
