import { ReactComponent as BellIcon } from "../../assets/icons/bell-icon.svg";
import { ReactComponent as MenuIcon } from "../../assets/icons/dashboard-menuIcon.svg";
// import { ReactComponent as LogoutIcon } from "../../assets/icons/logout-icon.svg";
import { ReactComponent as LogoutIcon } from "../../assets/icons/logout-icon2.svg";
import { ReactComponent as RightIcon } from "../../assets/icons/current-icon.svg";
import { ReactComponent as LeftIcon } from "../../assets/icons/left-icon.svg";
import { ReactComponent as LogoutIconTwo } from "../../assets/icons/logout-iconTwo.svg";
import { ReactComponent as UserIcon } from "../../assets/icons/user-iconTwo.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as DownArrow } from "../../assets/icons/down-arrow.svg";
import { useAuthUserContext } from "../../contexts/AuthContext";
import { useAuthContext } from "../../contexts/AuthContext";
import { useSeriesDetailsContext } from "../../contexts/SeriesContext";
import { useState } from "react";
import "./userDetailsBar.styles.css";

const UserDetailsBar = ({
  pathname,
  adminSideideBarLinks,
  logOutPrompt,
  setLogOutPropmt,
}) => {
  const [showMobileNav, setShowMobileNav] = useState(false);
  const [openDropdown, setOpenDropDown] = useState(false);

  const { singleSeriesDetails } = useSeriesDetailsContext();

  const navigate = useNavigate();

  const { setAuthDetails } = useAuthContext();

  let pathTitle = "";

  switch (pathname) {
    case "/company-panel/series":
      pathTitle = "Series";
      break;
    case "/company-panel/profile":
      pathTitle = "Profile";
      break;
    case "/company-panel/leaderboard":
      pathTitle = "Leaderboard";
      break;
    case "/company-panel/progress":
      pathTitle = "My Progress";
      break;
    case "/company-panel/admin-dashboard":
      pathTitle = "Admin Dashboard";
      break;
    case "/company-panel/users":
      pathTitle = "Users";
      break;
    case `/company-panel/series-details/${singleSeriesDetails?.id}`:
      pathTitle = `Series  >  ${singleSeriesDetails?.title}`;
      break;

    default:
      pathTitle = "";
  }

  const { authUserData } = useAuthUserContext();

  const toggleMobileMenu = () => {
    setShowMobileNav(!showMobileNav);
  };

  // For getting username acronym

  const str = `${authUserData.user?.name.toUpperCase()}`;
  const matches = str.match(/\b(\w)/g);
  const acronym = matches.join("");

  console.log(str);
  let id = 30;

  return (
    <div className="userDetails__Bar--container">
      <span className="userDetails__content--container">
        {pathname ===
          `/company-panel/series-details/${singleSeriesDetails?.id}` ||
        pathname === `/panel/series-details/${singleSeriesDetails?.id}` ? (
          <span className="mobile__back--nav" onClick={() => navigate(-1)}>
            <LeftIcon />
            <p style={{ marginLeft: "1rem" }}>Back</p>
          </span>
        ) : (
          <p className="page__heading hide__mobile--title">{pathTitle}</p>
        )}
        <span className="currentLink-and-title hidden">
          {pathname ===
            `/company-panel/series-details/${singleSeriesDetails?.id}` ||
          pathname === `/panel/series-details/${singleSeriesDetails?.id}` ? (
            <span className="series__and__details">
              <p
                className="current__link"
                onClick={() => navigate(-1)}
                style={{ cursor: "pointer" }}
              >
                Series
              </p>
              <p className="current__link current__series">
                <RightIcon style={{ margin: "0 2rem" }} />
                {singleSeriesDetails?.title}
              </p>
            </span>
          ) : (
            <p className="page__heading">{pathTitle}</p>
          )}
        </span>
        <span className="notification-and-userName">
          <span className="notification-icon-and-number">
            {/* <MenuIcon className="show__menu--CTA" onClick={toggleMobileMenu} /> */}
            {/* <BellIcon className="hide-notification" />
            <p className="notification__number hide-notification">4</p> */}
          </span>

          <span
            className={
              pathname === "/company-panel/profile"
                ? "hide"
                : "username-and-arrow"
            }
          >
            <p className="username__abbr">{acronym}</p>
            {authUserData.user?.type === "admin" ? (
              <span className="user__name-and-user__type">
                <p className="username hide__name">{authUserData.user?.name}</p>
                <p
                  className="user__type hide__name"
                  style={{ color: "#989898", fontSize: ".9rem" }}
                >
                  Admin
                </p>
              </span>
            ) : (
              <p className="username">{authUserData.user?.name}</p>
            )}
            <DownArrow
              className={
                openDropdown
                  ? "downarrow__in__detailsBar--flipped"
                  : "downarrow__in__detailsBar"
              }
              onClick={() => setOpenDropDown(!openDropdown)}
            />
            {openDropdown ? (
              <span className="logout-and-profile__link-CTAs">
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    borderBottom: "0.5px solid #F2F5F9",
                    paddingBottom: "2rem",
                  }}
                  onClick={() => {
                    setLogOutPropmt(true);
                  }}
                >
                  <LogoutIconTwo />{" "}
                  <p className="dropdown__prompts--text"> Log Out</p>
                </span>
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    paddingTop: "2rem",
                  }}
                  onClick={() => {
                    navigate("/company-panel/profile");
                  }}
                >
                  <UserIcon />{" "}
                  <p className="dropdown__prompts--text">My Profile</p>
                </span>
              </span>
            ) : null}
          </span>
          {pathname === "/company-panel/profile" ? (
            <span
              className="logout__CTA-and-text"
              onClick={() => {
                setLogOutPropmt(true);
              }}
            >
              <LogoutIcon />
              <p className="logout__text">Log out</p>
            </span>
          ) : (
            ""
          )}
          {/* <span
            className="logout__CTA-and-text"
            onClick={() => {
              localStorage.clear();
              setAuthDetails("");
            }}
          >
            <LogoutIcon />
            <p className="logout__text">Log out</p>
          </span> */}
        </span>
      </span>
      {/* {showMobileNav ? (
        <div className={showMobileNav ? "mobile__menu" : "hide__mobile-menu"}>
          <ul className="side__nav--links">
            {sideBarLinks.map((link) => {
              return (
                <NavLink
                  to={`${link.urlLink}`}
                  className={({ isActive }) =>
                    isActive ? "active" : "link__and__icon"
                  }
                  key={id++}
                >
                  {link.urlIcon} <li className="link">{link.urlName}</li>
                </NavLink>
              );
            })}
          </ul>

          <Link to="/">
            <span
              className="link__and__icon logout__CTA"
              onClick={() => localStorage.clear()}
            >
              <LogoutIcon />
              <p className="link ">Log out</p>
            </span>
          </Link>
        </div>
      ) : null} */}
    </div>
  );
};

export default UserDetailsBar;
