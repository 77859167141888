import { useEffect, useState, useMemo } from "react";
import { ReactComponent as SuccessClose } from "../../assets/icons/success-icon-close.svg";
import { ReactComponent as FailedClose } from "../../assets/icons/failed-icon-close.svg";
import { ReactComponent as SuccessInfo } from "../../assets/icons/success-info-icon.svg";
import { ReactComponent as FailedInfo } from "../../assets/icons/failed-info-icon.svg";

import "./alertComponent.css";

const AlertComponent = ({ status, setStatus, successText, failedText }) => {
  return (
    <>
      {status === true && (
        <div className="success__alert__container">
          <SuccessInfo />
          <p className="alert__text">{successText}</p>{" "}
          <SuccessClose
            onClick={() => setStatus("")}
            style={{ cursor: "pointer" }}
          />
        </div>
      )}
      {status === false && (
        <div className="failed__alert__container">
          <FailedInfo />
          <p className="alert__text">{failedText}</p>{" "}
          <FailedClose
            onClick={() => setStatus("")}
            style={{ cursor: "pointer" }}
          />
        </div>
      )}
    </>
  );
};

export default AlertComponent;
