import { useState, useRef, useEffect } from "react";
import { ReactComponent as FezzantLogo } from "../../assets/icons/fezzant-beta.svg";
import { ReactComponent as MailLogo } from "../../assets/icons/mail-icon.svg";
import { ReactComponent as CompnyIcon } from "../../assets/icons/company-icon.svg";
import { useForm } from "react-hook-form";
import axios from "axios";
import { ReactComponent as EyeLogo } from "../../assets/icons/eye-icon.svg";
import { ReactComponent as EyeOffLogo } from "../../assets/icons/eye-off.svg";
import { ReactComponent as LockLogo } from "../../assets/icons/lock-icon.svg";
import { ReactComponent as NameIcon } from "../../assets/icons/name-icon.svg";
import { ReactComponent as CountryIcon } from "../../assets/icons/country-icon.svg";
import { ReactComponent as DoneIcon } from "../../assets/icons/done-icon.svg";
import { useNavigate } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import Loading from "../Loading/Loading.component";
import "./createCompAcc.styles.css";
import OverlayPrompt from "../OverlayPrompt/OverlayPrompt.component";

const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const REGCOMPURL = "/api/v1/business/create";

mixpanel.init("f3574152559b2e61aa7f78dc5a2dee86", {
  debug: true,
});

const CreateCompAcc = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("password");

  const [email, setEmail] = useState("");
  const [emailFocus, setEmailFocus] = useState(false);

  const [firstName, setFirstName] = useState("");

  const [lastName, setLastName] = useState("");

  const [company, setCompany] = useState("");
  const [companyFocus, seCompanyFocus] = useState(false);

  const [country, setCountry] = useState("Nigeria");

  const [pwd, setPwd] = useState("");
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);

  const [matchPwd, setMatchPwd] = useState("");
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);

  const emailRef = useRef();
  const errRef = useRef();

  const navigate = useNavigate();

  const togglePasswordType = () => {
    setShowPassword(!showPassword);
    setType(showPassword ? "text" : "password");
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const name = firstName + " " + lastName;

  const onSubmit = async (data, e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const response = await axios.post(REGCOMPURL, {
        companyName: company,
        country: country,
        name: name,
        email: email,
        password: pwd,
      });
      setSuccess(true);
      mixpanel.track("CREATE-ACCOUNT-SUCCESS");
      setCompany("");
      setCountry("");
      setFirstName("");
      setLastName("");
      setEmail("");
      setShowPassword("");
      setLoading(false);
    } catch (error) {
      if (!error?.response) {
        mixpanel.track("CREATE-ACCOUNT-FAIL");
        setErrMsg("No Server Response");
      } else if (error.response?.status === 400) {
        setErrMsg("Account Already Exists");
      } else {
        setErrMsg("Registration Failed");
      }

      errRef.current.focus();
      setLoading(false);
    }
  };

  useEffect(() => {
    const result = PWD_REGEX.test(pwd);
    setValidPwd(result);
    const match = pwd === matchPwd;
    setValidMatch(match);
  }, [pwd, matchPwd]);

  useEffect(() => {
    setErrMsg("");
  }, [pwd, matchPwd]);

  const goToLogin = () => {
    navigate("/");
  };

  return (
    <>
      {success ? (
        <OverlayPrompt
          promptText="Success! Company Account Created"
          promptButtonTextOne="Log In"
          icon={<DoneIcon />}
          functionOne={goToLogin}
        />
      ) : null}

      <div className="createCompLogin__container">
        {loading ? <div className="overlay"></div> : null}
        {loading ? <Loading type="cylon" color="rgb(101, 93, 138)" /> : null}
        <div className="createCompLogin__content--container">
          <div className="createCompLogin__form--container">
            <span className="createCompAccNavLogo-and-links">
              <FezzantLogo />
            </span>
            <div className="form-and-text__container">
              <span
                className="createCompLogin__form--texts"
                onClick={() => (localStorage.token ? navigate("/panel") : "")}
              >
                {loading ? (
                  <Loading type="cylon" color="rgb(101, 93, 138)" />
                ) : null}
                <h2 className="createCompLogin__formheading">
                  Create Company Account
                </h2>

                <p
                  className={errorMessage ? "errorMessage" : "offScreen"}
                  aria-live="assertive"
                >
                  {errorMessage}
                </p>

                <p className="createCompLogin__form-sub--text">
                  Fill the form below to create an account for your business or
                  company.
                </p>
              </span>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="createCompLogin__form"
              >
                <label
                  htmlFor="email"
                  className="createCompLogin__email--label"
                >
                  Email Address
                </label>
                <div className="createCompLogin__email-and-icon">
                  <input
                    type="email"
                    id="email"
                    ref={emailRef}
                    autoc
                    omplete="off"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    required
                    aria-describedby="uidnote"
                    onFocus={() => setEmailFocus(true)}
                    onBlur={() => setEmailFocus(false)}
                    className={
                      errors.email
                        ? "email--input"
                        : "createCompLogin__email--input"
                    }
                    // {...register("email", { required: true })}
                  />
                  {errors.email && (
                    <p
                      style={{
                        color: "#F95959",
                        marginTop: "1rem",
                      }}
                    >
                      Email is required
                    </p>
                  )}
                  <MailLogo className="mail__icon" />
                </div>
                <label
                  htmlFor="firstName"
                  className="createCompLogin__email--label"
                >
                  First Name
                </label>
                <div className="createCompLogin__email-and-icon">
                  <input
                    type="text"
                    className={
                      errorMessage
                        ? "email--input"
                        : "createCompLogin__email--input"
                    }
                    id="firstName"
                    autoComplete="off"
                    onChange={(e) => setFirstName(e.target.value)}
                    value={firstName}
                    required
                    aria-describedby="uidnote"
                    onFocus={() => setEmailFocus(true)}
                    onBlur={() => setEmailFocus(false)}
                    // {...register("firstName", { required: true })}
                  />
                  {errors.firstName && (
                    <p
                      style={{
                        color: "#F95959",
                        marginTop: "1rem",
                      }}
                    >
                      First name is required
                    </p>
                  )}
                  <NameIcon className="mail__icon" />
                </div>
                <label
                  htmlFor="LastName"
                  className="createCompLogin__email--label"
                >
                  Last Name
                </label>
                <div className="createCompLogin__email-and-icon">
                  <input
                    type="text"
                    className={
                      errorMessage
                        ? "email--input"
                        : "createCompLogin__email--input"
                    }
                    id="lastName"
                    autoComplete="off"
                    onChange={(e) => setLastName(e.target.value)}
                    value={lastName}
                    required
                    aria-describedby="uidnote"
                    // {...register("lastName", { required: true })}
                  />
                  {errors.lastName && (
                    <p
                      style={{
                        color: "#F95959",
                        marginTop: "1rem",
                      }}
                    >
                      Last name is required
                    </p>
                  )}
                  <NameIcon className="mail__icon" />
                </div>

                <label
                  htmlFor="company"
                  className="createCompLogin__email--label"
                >
                  Company Name
                </label>
                <div className="createCompLogin__email-and-icon">
                  <input
                    className={
                      errorMessage
                        ? "email--input"
                        : "createCompLogin__email--input"
                    }
                    type="text"
                    id="company"
                    autoComplete="off"
                    onChange={(e) => setCompany(e.target.value)}
                    value={company}
                    required
                    aria-describedby="uidnote"
                    onFocus={() => seCompanyFocus(true)}
                    onBlur={() => seCompanyFocus(false)}
                    // {...register("company", { required: true })}
                  />
                  {errors.company && (
                    <p
                      style={{
                        color: "#F95959",
                        marginTop: "1rem",
                      }}
                    >
                      Company name is required
                    </p>
                  )}

                  <CompnyIcon className="mail__icon" />
                </div>
                <label
                  htmlFor="country"
                  className="createCompLogin__email--label"
                >
                  Country
                </label>
                <div className="createCompLogin__email-and-icon">
                  <select
                    // name="countries"
                    id="country"
                    className={
                      errorMessage
                        ? "email--input"
                        : "createCompLogin__email--input"
                    }
                    value={country}
                    required
                    onChange={(e) => setCountry(e.target.value)}
                    // {...register("country", { required: true })}
                  >
                    <option value=""></option>
                    <option value="Nigeria">Nigeria</option>
                    <option value="Ghana">Ghana</option>
                    <option value="Cameroun">Cameroun</option>
                  </select>
                  {errors.country && (
                    <p
                      style={{
                        color: "#F95959",
                        marginTop: "1rem",
                      }}
                    >
                      Please Select Your Country
                    </p>
                  )}

                  <CountryIcon className="mail__icon" />
                </div>
                <label
                  htmlFor="password"
                  className="createCompLogin__password--label"
                >
                  Password
                </label>
                <div className="createCompLogin__password-and-icons">
                  <input
                    type={type}
                    className={
                      errorMessage || (pwdFocus && !validPwd)
                        ? "email--input"
                        : "createCompLogin__email--input"
                    }
                    id="password"
                    // value={pwd}
                    onChange={(e) => setPwd(e.target.value)}
                    autoComplete="false"
                    required
                    aria-invalid={validPwd ? "false" : "true"}
                    aria-describedby="pwdnote"
                    onFocus={() => setPwdFocus(true)}
                    onBlur={() => setPwdFocus(false)}
                    // {...register("password", { required: true })}
                  />
                  <p
                    id="pwdnote"
                    className={
                      pwdFocus && !validPwd ? "instruction" : "offScreen"
                    }
                  >
                    8 to 24 characters.
                    <br />
                    Must include uppercase and lowercase letters, a number and a
                    special character.
                    <br />
                    Allowed special characters:{" "}
                    <span aria-label="exclamation mark">!</span>{" "}
                    <span aria-label="at symbol">@</span>{" "}
                    <span aria-label="hashtag">#</span>{" "}
                    <span aria-label="dollar sign">$</span>{" "}
                    <span aria-label="percent">%</span>
                  </p>

                  {type === "password" ? (
                    <EyeLogo
                      className="eye__icon"
                      onClick={togglePasswordType}
                    />
                  ) : (
                    <EyeOffLogo
                      className="eye__icon"
                      onClick={togglePasswordType}
                    />
                  )}
                  <LockLogo className="lock__icon" />
                </div>
                <label
                  htmlFor="confirm_pwd"
                  className="createCompLogin__password--label"
                >
                  Confirm Password
                </label>
                <div className="createCompLogin__password-and-icons">
                  <input
                    type={type}
                    id="confirm_pwd"
                    value={matchPwd}
                    onChange={(e) => setMatchPwd(e.target.value)}
                    autoComplete="false"
                    required
                    aria-invalid={validMatch ? "false" : "true"}
                    aria-describedby="confirmNote"
                    onFocus={() => setMatchFocus(true)}
                    onBlur={() => setMatchFocus(false)}
                    className={
                      errorMessage || (matchFocus && !validMatch)
                        ? "email--input"
                        : "createCompLogin__email--input"
                    }

                    // {...register("confirmPassword", { required: true })}
                  />
                  <p
                    id="confirmnote"
                    className={!validMatch ? "instruction" : "offScreen"}
                  >
                    Must match the first password input field.
                  </p>

                  {type === "password" ? (
                    <EyeLogo
                      className="eye__icon"
                      onClick={togglePasswordType}
                    />
                  ) : (
                    <EyeOffLogo
                      className="eye__icon"
                      onClick={togglePasswordType}
                    />
                  )}
                  <LockLogo className="lock__icon" />
                </div>

                <p className="forgot__password">Forgot Your Password</p>

                <button
                  disabled={!validPwd || !validMatch ? true : false}
                  className="createCompLogin__submit--CTA"
                >
                  Create Account
                </button>
              </form>
            </div>
          </div>
          <div className="createCompLogin__image--container">
            <div className="createCompLogin__image--box">
              <img
                src={require("../../assets/images/createcompacc.png")}
                alt="createCompLogin__image"
              />
            </div>
            <h1 className="createCompLogin__main--text">
              Built to bridge the gap between
              <br />
              security and technology
            </h1>
            <p className="createCompLogin__sub--text">
              Create an account to start protecting your organization
              <br /> from cyberattacks.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateCompAcc;
