import axios from "axios";
import { useState, useEffect, useMemo } from "react";
import ActivityHeatMap from "../ActivityHeatMap/ActivityHeatMap.component";
import Series from "../Series/Series.component";
import Loading from "../Loading/Loading.component";
import { useAuthUserContext } from "../../contexts/AuthContext";
import ProgressBar from "../ProgressBar/ProgressBar.component";
import OverlayPrompt from "../OverlayPrompt/OverlayPrompt.component";
import GAMetaDecorator from "../GAMetaDecorator/GAMetaDecorator";
import "./progress.styles.css";

const Progress = () => {
  const [onGoingAndCompleted, setOngoingAndCompleted] = useState([]);
  const [percentageCompleted, setPercentageCompleted] = useState("");
  const [userProgress, setUserProgress] = useState("");
  const [loading, setLoading] = useState(false);

  const token = localStorage.getItem("token");

  const { authUserData } = useAuthUserContext();

  const config = useMemo(() => {
    return {
      headers: { Authorization: `Bearer ${token}` },
    };
  }, [token]);

  useEffect(() => {
    setLoading(true);
    axios
      .get("/api/v1/series/getongoingandcompletedseries", config)
      .then((res) => {
        console.log();
        setOngoingAndCompleted(res.data?.series);
        setPercentageCompleted(res.data.series[0]?.percentage);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }, [config]);

  useEffect(() => {
    setLoading(true);
    axios
      .get("/api/v1/user/userprogress", config)
      .then((res) => {
        setUserProgress(res.data.progress);

        // setOngoingAndCompleted(res.data?.series);
        // setPercentageCompleted(res.data.series[0]?.percentage);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }, [config]);

  console.log({ onGoingAndCompleted });

  return (
    <>
      <GAMetaDecorator
        title="My-Progress-page"
        description="Fezzant's dashboard login page"
      />
      <div className="progress__details--container">
        {loading ? <Loading type="cylon" color="rgb(101, 93, 138)" /> : null}
        {/* {authUserData.subInfo?.isActive ? null : (
        <OverlayPrompt
          promptText="Renew your plan to see how far you've come young Jedi."
          promptButtonText="Renew Subcription"
        />
      )} */}

        <p className="curve__heading">Overall Training Progress</p>
        <div className="progress__details--box">
          <div className="progress__chart--container">
            {/* <ActivityHeatMap /> */}

            <img
              src={require("../../assets/images/series-img1.png")}
              alt="series__image1"
            />
            <span className="progress__no-and-bar">
              <p className="my__progress--percent">{userProgress}%</p>
              <ProgressBar done={userProgress} width="100%" />
            </span>
          </div>
        </div>
        <div className="ongoing-and-completed--container">
          <h1 className="ongoing-and-completed--heading">
            Ongoing and Completed Series
          </h1>
          <span className="series__progress--container">
            {onGoingAndCompleted.map((series) => {
              return (
                <Series
                  key={series.id}
                  title={series.title}
                  image={series.thumbnail}
                  seriesId={series.id}
                  points={series.totalPoints}
                  content={series.Contents}
                  percentageCompleted={percentageCompleted}
                />
              );
            })}
          </span>
        </div>
      </div>
    </>
  );
};

export default Progress;
