import { Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
import UserDetailsBar from "../../UserDetailsBar/UserDetailsBar.component";
import "./dashboard.styles.css";

const Dashboard = ({ sideBarLinks }) => {
  const location = useLocation();

  return (
    <div className="dashboard__container">
      <UserDetailsBar
        pathname={location.pathname}
        sideBarLinks={sideBarLinks}
      />
      <Outlet />
    </div>
  );
};

export default Dashboard;
