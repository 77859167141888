import { useEffect, useState, useMemo } from "react";
import { ReactComponent as CrownIcon } from "../../assets/icons/crown.svg";
import { ReactComponent as VerifiedIcon } from "../../assets/icons/verified.svg";
import { ReactComponent as DecreaseIcon } from "../../assets/icons/decrease.svg";
import { ReactComponent as IncreaseIcon } from "../../assets/icons/plus.svg";
import { useNavigate } from "react-router-dom";
import Loading from "../Loading/Loading.component";
import { useAuthUserContext } from "../../contexts/AuthContext";
import { useAuthContext } from "../../contexts/AuthContext";
import mixpanel from "mixpanel-browser";
import { ReactComponent as XIcon } from "../../assets/icons/x-svg.svg";
// import PaystackHook from "../Paystack/Paystack.component";
import axios from "axios";
import ReactGA from "react-ga4";

import "./paymentOptions.styles.css";

const CREATEPLANURL = "/api/v1/subscription/createPlan";

mixpanel.init("f3574152559b2e61aa7f78dc5a2dee86", {
  debug: true,
});

const PaymentOptions = () => {
  const [plan, setPlan] = useState("monthly");
  const [planDetails, setPlanDetails] = useState();
  const [noOfUsers, setNoOfUsers] = useState(3);
  const [disableButton, setDisableButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sessionUrl, setSessionUrl] = useState("");

  const { authUserData, setAuthUserData } = useAuthUserContext();
  const { setAuthDetails } = useAuthContext();

  /////// Google analytics custom event functions
  const gAPaymentStarted = (amount, noOfUsers) => {
    ReactGA.event({
      category: "PAYMENT-STARTED",
      action: "PAYMENT-STARTED",
      label: `Amount - ${amount}, No Of Users - ${noOfUsers}`, // optional
      value: 99, // optional, must be a number
      nonInteraction: true, // optional, true/false
      transport: "xhr", // optional, beacon/xhr/image
    });
  };

  const pricePerUser =
    plan === "monthly" ? planDetails?.basePrice : planDetails?.basePrice * 365;
  const priceForAllUsers = pricePerUser * noOfUsers;
  // const VAT = 0.05 * priceForAllUsers;
  const totalPrice = priceForAllUsers;

  const amount = totalPrice * 100;

  const toggleMonthlyPlan = () => {
    if (plan === "annually") {
      setPlan("monthly");
    }
  };
  const toggleAnnualPlan = () => {
    if (plan === "monthly") {
      setPlan("annually");
    }
  };

  const token = localStorage.getItem("token");

  const config = useMemo(() => {
    return {
      headers: { Authorization: `Bearer ${token}` },
    };
  }, [token]);

  const getPlan = () => {
    setLoading(true);
    axios
      .get("/api/v1/business/getplan", config)
      .then((res) => {
        setPlanDetails(res.data?.plan);

        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  const initiateStripePayment = async () => {
    // mixpanel.track("PAYMENT-STARTED", {
    //   price: `${amount}`,
    //   userCount: `${noOfUsers}`,
    // });

    gAPaymentStarted(amount, noOfUsers);

    console.log(token);
    try {
      setLoading(true);
      const response = await axios.post(
        CREATEPLANURL,
        {
          name: "mmonthlyplans",
          amount: amount,
          email: authUserData.user.email,
          noOfUsers: noOfUsers,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setSessionUrl(response.data.session_url);
      console.log(response.data.session_url);
      window.open(`${response.data.session_url}`, "_blank");

      setLoading(false);
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };

  useEffect(() => {
    getPlan();
  }, []);

  const navigate = useNavigate();

  return (
    <div className="payment__options--div">
      <div className="payment__overlay"></div>
      {loading ? <Loading type="cylon" color="rgb(101, 93, 138)" /> : null}
      <div className="paymentOptions__container">
        <div className="payment__options--container-content">
          <span className="sub__header__and__cancel--CTA">
            <span className="subscription__title">
              <h1 className="subscription">Subscription</h1>
              <p className="fezzant__teams">Fezzant For Teams</p>
            </span>
            <XIcon
              onClick={() => {
                localStorage.clear();
                setAuthUserData("");
                setAuthDetails("");
                navigate("/");
              }}
            />
          </span>
          <span className="plan__options">
            {/* <span className="monthly__and__border">
              <p
                className={plan === "monthly" ? "monthly" : "unselect__plan"}
                onClick={toggleMonthlyPlan}
              >
                Monthly
              </p>
              <div
                className={
                  plan === "monthly" ? "border__bottom" : "hide__border"
                }
              ></div>
            </span> */}
            <span className={"annually__and__border"}>
              <p className="annually" onClick={toggleAnnualPlan}>
                Annually
              </p>
              <div
                className={
                  plan === "annually" ? "border__bottom" : "hide__border"
                }
              ></div>
            </span>
          </span>
          <span className="planType__and__price">
            <h3 className="forTeams">Fezzant For Teams</h3>
            <span className="price__and__user">
              <p className="price">
                <span>&#8358;</span> {pricePerUser}
              </p>
              <p className="user__per--month">User Per Year</p>
            </span>
          </span>
          <span className="plan__benefits">
            <span className="benefit__span">
              <CrownIcon />
              <p className="access"> Access to all series and scenarios</p>
            </span>

            <span className="benefit__span">
              <VerifiedIcon />
              <p className="access">
                {plan === "monthly" ? "Monthly" : "Annual"} employee report
              </p>
            </span>
            <span className="benefit__span">
              <VerifiedIcon />
              <p className="access">3 users and above</p>
            </span>
            <span className="benefit__span">
              <VerifiedIcon />
              <p className="access">Access to all series and scenarios</p>
            </span>
          </span>
          <span className="number__of__users">
            <span className="no__of__users">
              <p className="no__of__users--main">No. of Users</p>
              <p className="no__of__users--sub">Starting at 3 Users</p>
            </span>
            <span className="num__of__users-and-control">
              <DecreaseIcon
                style={{ cursor: "pointer" }}
                onClick={() => {
                  noOfUsers > 3
                    ? setNoOfUsers(noOfUsers - 1)
                    : setNoOfUsers(noOfUsers);
                }}
              />

              <p className="num__of__users">{noOfUsers}</p>
              <IncreaseIcon
                style={{ cursor: "pointer" }}
                onClick={() => setNoOfUsers(noOfUsers + 1)}
              />
            </span>
          </span>
          <div className="plan__cost--summary">
            <span className="plan__cost">
              <p className="plan">Plan</p>
              <p className="cost__amount">
                <span>&#8358;</span> {priceForAllUsers}
              </p>
            </span>
            {/* <span className="vat__cost">
              <p className="vat">VAT/Tax</p>
              <p className="cost__amount">
                <span>&#8358;</span> {VAT}
              </p>
            </span> */}
            <span className="total__cost">
              <p className="total">Total</p>
              <p className="total__amount">
                {" "}
                <span>&#8358;</span> {totalPrice}
              </p>
            </span>
          </div>
          <span className="terms__agreement">
            <input
              type="checkbox"
              className="terms__tick"
              onClick={() => setDisableButton(!disableButton)}
            />
            <span className="term__agreement--prompt">
              <p className="term__agreement--text">
                By Proceeding, I confirm that I've read and agree to the
                <br />
                <span style={{ color: "#655D8A", fontWeight: "400" }}>
                  Terms of Use & Privacy Policy
                </span>
              </p>
            </span>
          </span>
          <span className="proceed__CTA--span">
            {/* <PaystackHook
              amount={amount}
              name={plan === "monthly" ? "monthly-plan" : "annual-plan"}
              interval={plan}
              Id={authUserData.businessId}
            /> */}
            <button
              onClick={initiateStripePayment}
              className="proceed__to__payment--CTA"
              disabled={disableButton ? false : true}
            >
              {" "}
              Proceed to payment
            </button>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PaymentOptions;
