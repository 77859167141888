import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as CloseIcon } from "../../assets/icons/close-circleTwo.svg";
import { ReactComponent as FezzantLogo } from "../../assets/icons/fezzantLogo-main.svg";
import { ReactComponent as Barge } from "../../assets/icons/barge.svg";
import { ReactComponent as ShareIcon } from "../../assets/icons/share-iconTwo.svg";
import { ReactComponent as DownloadIcon } from "../../assets/icons/download-icon.svg";
import { useSeriesDetailsContext } from "../../contexts/SeriesContext";
import { ReactComponent as CeoSignature } from "../../assets/icons/ceo-signature.svg";
import { useCertificateContext } from "../../contexts/CertificateContext";
import { useReactToPrint } from "react-to-print";

// import { ComponentToPrint } from "./ComponentToPrint";

import Pdf from "react-to-pdf";

import "./downloadCert.styles.css";

const DownloadCert = ({ showPassOrFail }) => {
  const { certificateData } = useCertificateContext();
  const { singleSeriesDetails } = useSeriesDetailsContext();

  console.log({ certificateData });

  const navigate = useNavigate();

  const ref = useRef();

  const options = {
    orientation: "landscape",
    unit: "in",
    format: [4, 2],
  };

  // const printMe = () => {
  //   var printContents = document.getElementById("printDiv").innerHTML;
  //   var originalContents = document.body.innerHTML;
  //   document.body.innerHTML = printContents;
  //   window.print();
  //   document.body.innerHTML = originalContents;
  // };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => navigate("/company-panel/series"),
    removeAfterPrint: true,
    documentTitle: `${singleSeriesDetails.title}`,
  });

  const nth = function (d) {
    if (d > 3 && d < 21) return "th";
    switch (d % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  console.log();

  const issued = certificateData?.dateIssued;

  const d = new Date(issued?.substr(0, 10));
  let month = months[d.getMonth()];
  const year = d.getFullYear();
  const day = d.getDate();
  const issuedDate = `${day}${nth(day)} of ${month}, ${year}`;
  console.log(issuedDate);

  useEffect(() => {
    // setTimeout(() => {

    // })
    handlePrint();
  }, []);

  return (
    <div className="download__cert--container" ref={componentRef}>
      <div className="cert__container--download">
        <div className="cert__content--container-down">
          <span className="logo-name-and-title">
            <FezzantLogo style={{ marginBottom: "1rem" }} />
            <p className="motto"> Securing your Human layer</p>
            <p className="cert_of_completion">Certificate of Completion</p>
            <h1 className="cert__course--title">
              {certificateData?.seriesName}
            </h1>
            <h1 className="cert__user--name">{certificateData?.name}</h1>
          </span>
          <div className="cert__desc-and-barge">
            <span className="desc-and-sign">
              <p className="cert__desc">
                Has demonstrated a solid understanding and awareness of [
                {certificateData?.seriesName}]. In recognition for this
                achievement, Fezzant is pleased to award you this certificate.
              </p>
              <span className="sign__and__date">
                <span className="sign__and__name">
                  {/* <p className="signature"> */}
                  <CeoSignature className="signature" />
                  {/* </p> */}
                  <p className="cert__Ceo">Ali Yisa, CEO of Fezzant</p>
                </span>
                <span className="cert__date--span">
                  <span className="date__ofissuance--span">
                    <p className="cert__date">{issuedDate}</p>
                  </span>
                  <p className="cert__date--text">Certification Date</p>
                </span>
              </span>
            </span>
            <div className="barge">
              <Barge />
              <p className="cert__number">
                {certificateData?.certificateNumber}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* <span className="download__cert-and-share--CTAs"> */}
      {/* <span className="share__and__continue--CTA"> */}
      {/* <span className="download__cert--CTA">
            <Pdf targetRef={ref} filename="my-fezzant-certificate.pdf">
              {({ toPdf }) => (
                <DownloadIcon style={{ cursor: "pointer" }} onClick={toPdf} />
              )}
              <DownloadIcon style={{ cursor: "pointer" }} />
            </Pdf>
          </span> */}
      {/* <span className="share__cert--CTA">
            <span className="share__pop--up">
              <p className="share__heading">Share</p>

              <span className="social__share--icons"></span>
            </span>
            <ShareIcon style={{ cursor: "pointer" }} />
          </span> */}
      {/* </span> */}
      {/* 
       <Pdf targetRef={ref} filename="my-fezzant-certificate.pdf">
        {({ toPdf }) => ( */}
      {/* // <DownloadIcon style={{ cursor: "pointer" }}  /> */}

      {/* <ReactToPrint
        trigger={() => <button className="continue__CTA">Download</button>}
        content={() => componentRef.current}
      /> */}
      {/* <button onClick={handlePrint} className="continue__CTA">
        {" "}
        Download!
      </button> */}

      {/* <button className="continue__CTA" onClick={() => printMe()}>
        Download{" "}
      </button> */}
      {/* )}
      </Pdf>  */}

      {/* </span> */}
    </div>
  );
};

export default DownloadCert;
