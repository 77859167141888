import { useState } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { collapseToast } from "react-toastify";
import "./circularProgressBar.styles.css";

const CircularProgressBar = ({ percentage }) => {
  let color;

  if (percentage <= 39) {
    color = "#C50C0C";
  } else if (percentage > 39 && percentage < 60) {
    color = "#FFE662";
  } else {
    color = "#6FCF97";
  }

  return (
    <div style={{ width: 35, height: 35 }}>
      <CircularProgressbar
        value={percentage}
        text={`${percentage}%`}
        styles={{
          path: {
            strokeLinecap: "butt",
            // transform: "rotate(-135deg)",
            transformOrigin: "center center",
            transition: "stroke-dashoffset 1.5s ease .5s",
            stroke: `${color}`,
          },

          text: {
            fill: `${color}`,
            fontWeight: "500",
            textAlign: "center",
            fontSize: "2rem",
          },
        }}
      />
    </div>
  );
};

export default CircularProgressBar;
