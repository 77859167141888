import { useNavigate } from "react-router-dom";
import { ReactComponent as LeftIcon } from "../../assets/icons/left-icon.svg";
import { ReactComponent as VideoIcon } from "../../assets/icons/video-icon.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/close-circleTwo.svg";
import "./startQuiz.css";

const StartQuiz = ({
  thumbnail,
  toggleShowVideoPlayer,
  takeQuiz,
  startQuiz,
  showStartQuiz,
}) => {
  const navigate = useNavigate();
  return (
    <div className="videoPlayer__container--two">
      <div className="start__quiz--container">
        <span className="close__start--quiz-span">
          <CloseIcon
            style={{ cursor: "pointer" }}
            onClick={() => takeQuiz()}
            className="hide__xIcon"
          />
          <span
            className="mobile__back--nav hide__back--arrow"
            onClick={() => navigate(-1)}
          >
            <LeftIcon />
            <p style={{ marginLeft: "1rem" }}>Back</p>
          </span>
        </span>
        <span className="completed__series--content">
          <h1 className="congrats__text" style={{ textAlign: "center" }}>
            Congratulations! You’ve just completed
            <br />
            the Email Security series 🎉
          </h1>
          <div
            className="series__completed--img"
            style={{
              backgroundImage: `url(${thumbnail})`,
            }}
          />
          <p className="series__completed--sub-text">
            Continue to take a short quiz to earn your certificate
          </p>

          <button className="continue__quiz--CTA" onClick={() => startQuiz()}>
            Start Quiz
          </button>
        </span>
      </div>
    </div>
  );
};

export default StartQuiz;
