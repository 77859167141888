import { useEffect, useState, useMemo } from "react";
import "chart.js/auto";
import axios from "axios";
import { ReactComponent as DownArrowIcon } from "../../assets/icons/downArrow-icon.svg";
import { ReactComponent as CrownIcon } from "../../assets/icons/crown.svg";
import { ReactComponent as VerifiedIcon } from "../../assets/icons/verified.svg";
import { ReactComponent as MoreIcon } from "../../assets/icons/more-circle.svg";
import { useAuthUserContext } from "../../contexts/AuthContext";
import OverlayPrompt from "../OverlayPrompt/OverlayPrompt.component";
import Loading from "../Loading/Loading.component";
import { v4 as uuidv4 } from "uuid";
import ActivityChart from "../ActivityChart/ActivityChart.component";
import MobileCarousel from "../MobileCarousel/MobileCarousel";
import GAMetaDecorator from "../GAMetaDecorator/GAMetaDecorator";
import "./adminDashboard.styles.css";

const AdminDashboard = () => {
  const [planDetails, setPlanDetails] = useState();
  const [openMonths, setOpenMonths] = useState(false);
  const [billingInfo, setBillingInfo] = useState(false);
  const [activityOverviewData, setActivityOverviewData] = useState([]);
  const [selected, setSelected] = useState("Monthly");
  const [showDropDown, setShowDropDown] = useState(false);
  const [loading, setLoading] = useState(false);

  const { authUserData } = useAuthUserContext();

  const nth = function (d) {
    if (d > 3 && d < 21) return "th";
    switch (d % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  console.log();

  const d = new Date(billingInfo.startDate?.substr(0, 10));
  let month = months[d.getMonth()];
  const year = d.getFullYear();
  const day = d.getDate();
  const nextSubDate = `${day}${nth(day)} of ${month}, ${year + 1}`;
  console.log(nextSubDate);

  console.log({ activityOverviewData });

  const labels = [" Started", "Completed", "Not Started"];

  // const toolTipLabel =

  const info = activityOverviewData.map((series, index) => {
    let data;
    return {
      // labels: [series.seriesName],
      name: series.seriesName,
      datasets: [
        {
          data: [
            series.ongoingCount,
            series.completedCount,
            series.notStartedCount,
          ],
          // label: labels[index],
          borderColor: ["rgba(255,206,86,0.2)"],
          backgroundColor: ["#0f80b3", "#6fcf97", "#f39090"],
          hoverOffset: 8,
          pointBackgroundColor: "rgba(255,206,86,0.2)",
        },
      ],
    };
  });

  console.log({ info });

  const titleTooltip = (tooltipItems) => {
    return labels.filter((label, index) => tooltipItems[index]);
  };

  const options = {
    plugins: {
      tooltip: {
        displayColors: true,
        // callbacks: {
        //   title: titleTooltip,
        // },
      },
    },
  };
  useEffect(() => {
    getActivityOverviewData();
    getPlan();
    getBillingInfo();
  }, []);

  const pricePerUser = planDetails?.basePrice;

  const amount = billingInfo.amount;

  const token = localStorage.getItem("token");

  const config = useMemo(() => {
    return {
      headers: { Authorization: `Bearer ${token}` },
    };
  }, [token]);

  const getPlan = () => {
    setLoading(true);
    axios
      .get("/api/v1/business/getplan", config)
      .then((res) => {
        setPlanDetails(res.data?.plan);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  const getBillingInfo = () => {
    setLoading(true);
    axios
      .get("/api/v1/subscription/getbillinginfo", config)
      .then((res) => {
        setBillingInfo(res.data?.data);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };
  const getActivityOverviewData = () => {
    setLoading(true);
    axios
      .get("/api/v1/business/activityOverview", config)
      .then((res) => {
        setActivityOverviewData(res.data?.seriesCount);
        console.log({ activityOverviewData });
        console.log(res.data?.seriesCount);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };
  console.log({ authUserData });

  return (
    <>
      <GAMetaDecorator
        title="Admin - Dashboard"
        description="Fezzant's Admin Dashboard"
      />
      <div className="admin__dashboard--container">
        {/* {authUserData.subInfo?.isActive ? null : (
        <OverlayPrompt
          promptText="Renew your plan to see your team's detailed analytics"
          promptButtonText="Renew Subcription"
        />
      )} */}
        <p className="adminDashboard__page--heading">Activity Overview</p>
        <div className="activity__charts--div">
          <span className="chart__legends-and-periodCTA">
            <span className="chart__legends">
              <div className="legend__color--one"></div>
              <p className="legend__label">Started</p>
              <div className="legend__color--two"></div>
              <p className="legend__label">Completed</p>
              <div className="legend__color--three"></div>
              <p className="legend__label">Not Started</p>
            </span>
            {/* <span
            className="period__select"
            onClick={() => setOpenMonths(!openMonths)}
          >
            <p className="activity__Period">{selected}</p>
            <DownArrowIcon style={{ marginLeft: "1rem" }} />
            {openMonths ? (
              <div className="months__dropdown--container">
                {months.map((month) => (
                  <span className="month__dropDown--options" key={uuidv4()}>
                    <p
                      className="data__month"
                      onClick={() => setSelected(month)}
                    >
                      {month}
                    </p>
                  </span>
                ))}
              </div>
            ) : (
              ""
            )}
          </span> */}
          </span>
          <div className="activity__charts--container">
            {info.map((data) => {
              return (
                <ActivityChart data={data} options={options} name={data.name} />
              );
            })}
            {/* <ActivityChart data={data} options={options} />
          <ActivityChart data={data} options={options} /> */}
          </div>
          <div className="activity__charts--container-mobile">
            <MobileCarousel info={info} options={options} />
          </div>
        </div>
        {/* {loading ? <Loading type="cylon" color="rgb(101, 93, 138)" /> : null} */}

        <div className="subscription__and__progress--container">
          <div className="admin__active__sub--container">
            <p className="active__heading">Subscriptions</p>
            <div className="active__plan--desc">
              <div className="admin__active__sub--benefits">
                <h3 className="active__sub--heading">
                  {" "}
                  {authUserData?.subInfo?.freetrial
                    ? "Fezzant for teams: Trial"
                    : "Fezzant for teams  "}
                </h3>
                <span className="benefits__span">
                  <CrownIcon className="benefits__icon" />
                  <p className="benefit">
                    {" "}
                    {authUserData?.subInfo?.freetrial
                      ? "Limited training access"
                      : "Access to all series and scenarios"}
                  </p>
                </span>
                <span className="benefit__span">
                  <VerifiedIcon className="benefits__icon" />
                  <p className="benefit"> Employee report</p>
                </span>
                <span className="benefit__span">
                  <VerifiedIcon className="benefits__icon" />

                  <p className="benefit">
                    {authUserData?.subInfo?.freetrial
                      ? "Maximum of 2 users"
                      : "3 Users and above"}
                  </p>
                </span>
              </div>

              <span className="active-sub--billing-info">
                <span className="price__and__options">
                  <h3 className="sub__price">
                    {" "}
                    <span>&#8358;</span> {pricePerUser}
                  </h3>
                  {/* <span className="more__icon-and-option__drop">
                  <MoreIcon
                    onClick={() => setShowDropDown(!showDropDown)}
                    style={{ cursor: "pointer" }}
                  />
                  {showDropDown ? (
                    <span className="sub__dropDown">
                      <p className="subDrop__option option__one">
                        Cancel Subscription
                      </p>
                      <p className="subDrop__option option__two">
                        Renew Subscription
                      </p>
                    </span>
                  ) : null}
                </span> */}
                </span>
                <p className="sub__per--user">Per User Per Year</p>
                <div className="line"></div>

                {authUserData.subInfo.isActive ? (
                  <p className="next__sub--charge">
                    Your next bill is for{" "}
                    <span style={{ fontWeight: "700", color: "#655D8A" }}>
                      <span>&#8358;</span> {amount}
                    </span>
                    <br />
                    on <span style={{ fontWeight: "700" }}>{nextSubDate}</span>
                  </p>
                ) : (
                  <p className="next__sub--charge">
                    Your free trial will expire in <br />
                    <span style={{ fontWeight: "700" }}>
                      {/* {nextSubDate} */}
                      {authUserData?.subInfo?.daysLeft} Days
                    </span>
                  </p>
                )}
              </span>
            </div>
            {/* <span className="cancel__subCTA--span">
            <button className="cancel__sub--CTA">Cancel subscription</button>
          </span> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminDashboard;
