import { useEffect, useState, useMemo } from "react";

import { ReactComponent as AddUser } from "../../assets/icons/add-user-icon.svg";
import { ReactComponent as EditDeptIcon } from "../../assets/icons/edit-dept-icon.svg";
import axios from "axios";
import Loading from "../Loading/Loading.component";
import UsersTableData from "../UsersTableData/UsersTableData.component";
import { Link } from "react-router-dom";
import AddUserForm from "../AddUserForm/AddUserForm";
import { v4 as uuidv4 } from "uuid";
import "./addUserToDept.styles.css";
import EditDepartment from "../EditDepartment/EditDepartment.component";
import AlertComponent from "../AlertComponent/AlertComponent";

const AddUserToDept = ({
  departments,
  showAddUserForm,
  setShowAddUserForm,
  showAddDept,
  setShowAddDept,
  showEditDept,
  setShowEditDept,
  showAssigntraining,
  setShowAssignTraining,
  setUsersData,
  setDeptToEdit,
  setDeptForAssign,
}) => {
  const [selectedDeptColor, setSelectedDeptColor] = useState("");
  const [key, setKey] = useState("");
  const [status, setStatus] = useState("");

  // const users = "zero";

  const permitAssignTraining = (users) => {
    if (users.length > 0) {
      setShowAssignTraining(!showAssigntraining);
      setStatus("");
    } else {
      // setAddUserPrompt(true);
      setStatus(false);
      // setTimeout(() => {
      //   setStatus("");
      // }, 4000);
    }
  };

  return (
    <div className="addUserToDept__container">
      <div className="alert__container--div">
        <AlertComponent
          status={status}
          setStatus={setStatus}
          // successText={successMss}
          failedText="You need to add users before you can assign training/series."
        />
      </div>
      <span className="dept__heading-and-CTAs">
        <h1 className="dept__heading">Departments</h1>
        <span className="dept__CTAs">
          <button
            className="add_userToDept--CTA"
            onClick={() => setShowAddUserForm(!showAddUserForm)}
          >
            {" "}
            {/* <EditDepartment /> */}
            <AddUser />
            <p style={{ marginLeft: ".8rem" }}>Add User</p>
          </button>
          <button
            className="add_addDept--CTA"
            onClick={() => setShowAddDept(!showAddDept)}
          >
            Add Department
          </button>
        </span>
      </span>
      <span
        className="mobile__addDept--CTA"
        onClick={() => setShowAddDept(!showAddDept)}
      >
        {" "}
        <span
          style={{
            fontWeight: "900",
            marginRight: "0.2rem",
          }}
        >
          +
        </span>
        Add Department
      </span>
      <div className="department__cards">
        {departments &&
          departments.map((department, index) => (
            <div
              key={uuidv4()}
              className="department__card--container"
              id={department.colorCode}
              name={index}
              style={{
                background:
                  selectedDeptColor === department.colorCode &&
                  selectedDeptColor !== "" &&
                  department.id === key
                    ? `${selectedDeptColor}`
                    : "#ffffff",
              }}
              onClick={(e) => {
                setSelectedDeptColor(e.target.id);
                setKey(department.id);
                setUsersData(department.users);
              }}
            >
              <span className="dept__name-and-edit">
                <p
                  className="dept__card--name"
                  style={{
                    color:
                      selectedDeptColor === department.colorCode &&
                      selectedDeptColor !== "" &&
                      department.id === key
                        ? "#ffffff"
                        : "#4A5567",
                  }}
                >
                  {department.name}
                </p>
                <EditDeptIcon
                  style={{
                    fill: department.colorCode ? "#fff" : "",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setShowEditDept(!showEditDept);
                    setDeptToEdit(department.id);
                  }}
                />
              </span>
              <p
                className="dept__card--membersNo"
                style={{
                  color:
                    selectedDeptColor === department.colorCode &&
                    selectedDeptColor !== "" &&
                    department.id === key
                      ? "#ffffff"
                      : "#c9c9c9",
                }}
              >
                {department.users?.length} Member(s)
              </p>
              <button
                className="assign__training--CTA"
                style={{
                  color:
                    selectedDeptColor === department.colorCode &&
                    selectedDeptColor !== "" &&
                    department.id === key
                      ? "#171531"
                      : "#D885A3",
                  background:
                    selectedDeptColor === department.colorCode &&
                    selectedDeptColor !== "" &&
                    department.id === key
                      ? "#ffffff"
                      : "#FFEAF2",
                }}
                // disabled={users === "zero" ? true : false}
                onClick={() => {
                  permitAssignTraining(department.users);
                  setDeptForAssign(department);
                  // disabled ? console.log("disabled") : console.log("active");
                }}
              >
                Manage Training
              </button>
            </div>
          ))}
      </div>
    </div>
  );
};

export default AddUserToDept;
