import { useState, useEffect } from "react";
import { ReactComponent as FezzantLogo } from "../../assets/icons/fezzant-beta-copy.svg";
import { ReactComponent as MailLogo } from "../../assets/icons/mail-icon.svg";
import { useForm } from "react-hook-form";
import { ReactComponent as EyeLogo } from "../../assets/icons/eye-icon.svg";
import { ReactComponent as EyeOffLogo } from "../../assets/icons/eye-off.svg";
import { ReactComponent as LockLogo } from "../../assets/icons/lock-icon.svg";
import { useAuthContext } from "../../contexts/AuthContext";
import mixpanel from "mixpanel-browser";
import { useNavigate, useLocation } from "react-router-dom";
import Loading from "../Loading/Loading.component";
import axios from "axios";
import ReactGA from "react-ga4";
import GAMetaDecorator from "../GAMetaDecorator/GAMetaDecorator";
import "./login.styles.css";
// import MetaDecorator from "../MetaDecorator/MetaDecorator";

// mixpanel.init("f3574152559b2e61aa7f78dc5a2dee86", {
//   debug: true,
// });

///// Google analytics custom event functions

const gALoggingSuccess = () => {
  ReactGA.event({
    category: "LOGIN-SUCCESS",
    action: "Clicked Login",
    label: "Clicked Login", // optional
    value: 99, // optional, must be a number
    nonInteraction: true, // optional, true/false
    transport: "xhr", // optional, beacon/xhr/image
  });
};
const gALoggingFailed = () => {
  ReactGA.event({
    category: "LOGIN-FAILED",
    action: "Clicked Login",
    label: "Clicked Login", // optional
    value: 99, // optional, must be a number
    nonInteraction: true, // optional, true/false
    transport: "xhr", // optional, beacon/xhr/image
  });
};

const Login = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [type, setType] = useState("password");

  const { authDetails, setAuthDetails } = useAuthContext();

  const navigate = useNavigate();
  const location = useLocation();

  // console.log(Math.floor(new Date().getTime() / 1000));

  // console.log(Math.round(new Date().getTime() / 1000));

  // if (authDetails?.userType) {
  // const userPanelUrl =
  //   authDetails?.userType === "staff"
  //     ? "/panel/profile"
  //     : "/company-panel/profile";
  // }
  const from = location.state?.from?.pathname || "/company-panel/series";
  // console.log(userPanelUrl);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const togglePasswordType = () => {
    setShowPassword(!showPassword);
    setType(showPassword ? "text" : "password");
  };

  const onSubmit = (data, e) => {
    e.preventDefault();
    setLoading(true);

    axios
      .post("/api/v1/user/login", data)
      .then((res) => {
        localStorage.setItem("token", res.data?.token);
        mixpanel.track("LOGIN-SUCCESS");
        gALoggingSuccess();
        setAuthDetails(res?.data);
        setSuccess(true);
        setLoading(false);

        navigate(from);
      })
      .catch((error) => {
        // mixpanel.track("LOGIN-FAILED");
        gALoggingFailed();
        if (!error?.response) {
          setErrorMessage(
            "Unable to connect. Please Check Your Internet Connection."
          );
        } else if (error.response.data.error) {
          setErrorMessage(error.response.data.error);
        } else {
          setErrorMessage(error.response.data.message);
        }
        setLoading(false);
      });
  };

  return (
    <>
      <GAMetaDecorator
        title="Fezzant - Login"
        description="Fezzant's dashboard login page"
      />
      <div className="login__container">
        {/* <div className="login__content--container"> */}
        <div className="login__form--container">
          {/* <span className="navLogo-and-links"> */}
          <FezzantLogo className="login__fezzant--logo" />
          {/* </span> */}
          <div className="form-and-text__container">
            <span className="login__form--texts">
              {loading ? (
                <Loading type="cylon" color="rgb(101, 93, 138)" />
              ) : null}
              <h2 className="login__formheading">Log in</h2>

              {errorMessage ? (
                <p
                  style={{
                    color: "#F95959",
                    marginBottom: "1rem",
                  }}
                >
                  {errorMessage}
                </p>
              ) : (
                ""
              )}
              <p className="login__form-sub--text">
                Enter your Email and Password
              </p>
            </span>
            <form
              action=""
              className="login__form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <label htmlFor="Email" className="login__email--label">
                Email Address
              </label>
              <div className="login__email-and-icon">
                <input
                  type="email"
                  className={
                    errors.email ? "email--input" : "login__email--input"
                  }
                  {...register("email", { required: true })}
                />
                {errors.email && (
                  <p
                    style={{
                      color: "#F95959",
                      marginTop: "1rem",
                    }}
                  >
                    Email is required
                  </p>
                )}
                <MailLogo className="mail__icon" />
              </div>
              <label htmlFor="password" className="login__password--label">
                Password
              </label>
              <div className="login__password-and-icons">
                <input
                  type={type}
                  className={
                    errors.password
                      ? "password--input"
                      : "login__password--input"
                  }
                  {...register("password", { required: true })}
                />
                {errors.password && (
                  <p
                    style={{
                      color: "#F95959",
                      marginTop: "1rem",
                    }}
                  >
                    Password is required
                  </p>
                )}
                {type === "password" ? (
                  <EyeLogo className="eye__icon" onClick={togglePasswordType} />
                ) : (
                  <EyeOffLogo
                    className="eye__icon"
                    onClick={togglePasswordType}
                  />
                )}
                <LockLogo className="lock__icon" />
              </div>

              <p className="forgot__passwordone">Forgot Your Password</p>

              <button className="login__submit--CTA">Log In</button>
            </form>
            <p className="dont__have-account">
              Don't have an account?{" "}
              <span style={{ color: "#655d8a" }}>
                <a href="https://share-eu1.hsforms.com/1sZ6AZim4S-axw0gaUVjkpgf3uwl">
                  Book a Demo
                </a>
              </span>
            </p>
            <span className="create__comp--CTA-span">
              <button
                className="create__comp--acc"
                onClick={() => navigate("/create-companyAcc")}
              >
                Create company account
              </button>
            </span>
          </div>
        </div>
        <div className="login__image--container">
          <div className="login__image--box">
            <img
              src={require("../../assets/images/booking-image.png")}
              alt="login__image"
            />
          </div>
          <h1 className="login__main--text">
            Designed to protect against
            <br />
            cyber attacks
          </h1>
          <p className="login__sub--text">
            Login to start protecting your organization
            <br /> from cyberattacks.
          </p>
        </div>
        {/* </div> */}
      </div>
    </>
  );
};

export default Login;
