import { useState, useMemo, useEffect } from "react";
import { ReactComponent as XIcon } from "../../assets/icons/x-iconTwo.svg";
import SearchFilter from "../SearchFilter/SearchFilter.component";
import axios from "axios";
import { ReactComponent as BackIcon } from "../../assets/icons/left-icon.svg";
import { v4 as uuidv4 } from "uuid";
import TrainingTab from "../TrainingTab/TrainingTab.component";
import AlertComponent from "../AlertComponent/AlertComponent";
import "./assignTraining.styles.css";

const AssignTraining = ({
  showAssignTraining,
  setShowAssignTraining,
  deptForAssign,
  allSeries,
}) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const [deptDetails, setDeptDetails] = useState("");
  const [selectedTrainings, setSelectedTrainings] = useState([]);
  const [selectedTrainingsIds, setSelectedTrainingsIds] = useState([]);
  const [status, setStatus] = useState("");
  const [successMss, setSuccessMss] = useState("");
  const [errMss, setErrMss] = useState("");
  const [staticIds, setStaticIds] = useState([]);

  const token = localStorage.getItem("token");

  const config = useMemo(
    () => ({
      headers: { Authorization: `Bearer ${token}` },
    }),
    [token]
  );

  useEffect(() => {
    getDepartment();
  }, [showAssignTraining]);

  console.log({ deptForAssign });
  const getDepartment = () => {
    console.log("ran");
    axios
      .get(`/api/v1/department/getdepartment/${deptForAssign.id}`, config)
      .then((res) => {
        setDeptDetails(res.data);
        setSelectedTrainings(res.data?.series);
        setStaticIds(res.data?.series);
      })
      .catch((err) => console.log(err));
  };

  console.log({ selectedTrainings });
  console.log({ selectedTrainingsIds });
  console.log({ staticIds });

  const addSelectedTraining = (trainingAdded) => {
    const filteredTrainings = selectedTrainings?.filter(
      (training) => training.title === trainingAdded.title
    );
    const filteredStaticIds = staticIds?.filter(
      (training) => training.title === trainingAdded.title
    );
    if (filteredTrainings.length || filteredStaticIds.length) {
      console.log({ filteredTrainings });
      setErrMss("Training already exists in department");
      setStatus(false);
      return;
    } else {
      setSelectedTrainings([...selectedTrainings, trainingAdded]);
      setStatus("");
    }
  };
  const addSelectedTrainingId = (trainingId) => {
    const filteredTrainingId = selectedTrainings.filter(
      (training) => training.id === trainingId
    );
    const filteredSaticId = staticIds.filter(
      (training) => training.id === trainingId
    );
    if (filteredTrainingId.length || filteredSaticId.lenght) {
      console.log({ filteredTrainingId });
      return;
    } else {
      setSelectedTrainingsIds([...selectedTrainingsIds, trainingId]);
    }
  };

  const removeItem = (trainingToRemove) => {
    setSelectedTrainings(
      selectedTrainings.filter(
        (training) => training.title !== trainingToRemove
      )
    );
  };
  const removeId = (trainingToRemove) => {
    setSelectedTrainingsIds(
      selectedTrainingsIds.filter((id) => id !== trainingToRemove)
    );
  };

  const assignTraining = () => {
    axios
      .put(
        "/api/v1/department/assignseries",
        { seriesId: selectedTrainingsIds, departmentId: deptForAssign.id },
        config
      )
      .then((res) => {
        console.log(res.data.message);
        setSuccessMss(res.data.message);
        setStatus(true);
        setTimeout(() => {
          setStatus("");
        }, [4000]);
        setSelectedTrainingsIds([]);
      })
      .catch((err) => {
        console.log(err);
        setErrMss(err.data.message);
        setStatus(false);
      });
  };

  const removeTraining = (id) => {
    axios
      .put(
        "/api/v1/department/removeseries",
        { seriesId: id, departmentId: deptForAssign.id },
        config
      )
      .then((res) => {
        console.log(res);
        setSuccessMss(res.data.message);
        setStatus(true);
        !selectedTrainings.length && getDepartment();
      })
      .catch((error) => console.log(error));
  };

  return (
    <div
      className="assign__training--container"
      onClick={() => (showDropDown === true ? setShowDropDown(false) : null)}
    >
      <div className="alert__container--div">
        <AlertComponent
          status={status}
          setStatus={setStatus}
          successText={successMss}
          failedText={errMss}
        />
      </div>
      <div className="assign__training--content">
        <div className="assign__training--content-box">
          <span className="add__dept--mobile-nav hide__mNav">
            <span
              style={{ display: "flex", alignItems: "center" }}
              onClick={() => setShowAssignTraining(false)}
            >
              <BackIcon style={{ marginRight: "1.2rem" }} /> <p>Back</p>
            </span>
            <p style={{ fontSize: "1.4rem", fontWeight: "400" }}>
              Assign Training
            </p>
          </span>
          <span className="assign__training--heading-and-close">
            <span className="assign__training--texts">
              <h1 className="assign__training--main-text">Manage Training</h1>
              <p className="assign__training--sub-text">Dept xyz</p>
            </span>
            <XIcon
              onClick={() => setShowAssignTraining(!showAssignTraining)}
              style={{ cursor: "pointer" }}
              className="hide__arrow"
            />
          </span>
          <label className="assign__training--input-label">
            Choose Trainings
          </label>
          {/* <div className="input-and-drop__icon">
            <input type="text" className="assign__training--input" />
          </div> */}
          <SearchFilter
            showDropDown={showDropDown}
            setShowDropDown={setShowDropDown}
            width="100"
            addSelectedTraining={addSelectedTraining}
            addSelectedTrainingId={addSelectedTrainingId}
            allSeries={allSeries}
          />
          <p
            className="members__heading--text"
            style={{ margin: "0 0 1rem 0" }}
          >
            Selected Trainings
          </p>
          <span className="assigned__trainings">
            {selectedTrainings?.map((training) => (
              <TrainingTab
                name={training.title}
                id={training.id}
                key={uuidv4()}
                removeItem={removeItem}
                removeId={removeId}
                removeTraining={removeTraining}
              />
            ))}
          </span>

          <button
            className="assign__Depttraining--CTA"
            disabled={!selectedTrainingsIds.length ? true : false}
            onClick={() => assignTraining()}
          >
            Assign Training
          </button>
        </div>
      </div>
    </div>
  );
};

export default AssignTraining;
