import ActivityChart from "../ActivityChart/ActivityChart.component";
import React, { Component } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const MobileCarousel = ({ info, options }) => {
  return (
    <Carousel
      showArrows={false}
      showStatus={false}
      showIndicators={false}
      showThumbs={false}
    >
      {info.map((data) => {
        return <ActivityChart data={data} options={options} name={data.name} />;
      })}
      {/* <ActivityChart data={data} options={options} />
          <ActivityChart data={data} options={options} /> */}
    </Carousel>
  );
};

export default MobileCarousel;
