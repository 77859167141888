import React, { useState, useContext, createContext } from "react";

const AuthContext = createContext();
const AuthUserContext = createContext();

export const useAuthContext = () => {
  return useContext(AuthContext);
};

export const useAuthUserContext = () => {
  return useContext(AuthUserContext);
};

export const AuthProvider = ({ children }) => {
  const [authDetails, setAuthDetails] = useState({});
  const [authUserData, setAuthUserData] = useState({});

  return (
    <AuthContext.Provider value={{ authDetails, setAuthDetails }}>
      <AuthUserContext.Provider value={{ authUserData, setAuthUserData }}>
        {children}
      </AuthUserContext.Provider>
    </AuthContext.Provider>
  );
};
